@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.contact-us {
    background-color: $bodybackgroundColor;


    .contact-page {
        padding-top: 80px;
    }

    .contact-tile-container {
        padding-top: 13rem;
        padding-bottom: 13rem;
        margin-bottom: 89px;
        background-image: url(../../../public/backgrounds/contact-us-bg.png);
        background-position: center;

        .contact-title {
            margin-bottom: 1rem;
            color: $colorWhite;
        }
    }
    .world-map{
        margin-bottom: 100px;
    }

    .contact-form-container {
        position: relative;
        margin-bottom: 8.125rem;
        z-index: 3;
        max-width: 78rem;

        /* .form-title {
        margin-top: 100px;
    } */

        .hubspot-form {
            margin-top: 4.25rem;
            margin-right: 120px;
            margin-left: 120px;
            color: $colorWhite;

            fieldset {
                width: 100%;
                max-width: 100% !important;
            }

            .hs-form-field input:-webkit-autofill,
            .hs-form-field input:-webkit-autofill:hover,
            .hs-form-field input:-webkit-autofill:focus,
            .hs-form-field textarea:-webkit-autofill,
            .hs-form-field textarea:-webkit-autofill:hover,
            .hs-form-field textarea:-webkit-autofill:focus,
            .hs-form-field input,
            .hs-form-field textarea {
                width: 100%;
                border: 1px solid $bgPurpleColor;
                filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
                border-radius: 4px;
                background-color: $secondaryPinkColor;
                padding: 1rem 1.6rem;
                color: black;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-background-clip: text;
                -webkit-text-fill-color: $colorWhite !important;
            }

            .hs-error-msg {
                color: red;
                margin-top: 10px;
            }

            .hs-form-field input::placeholder,
            .hs-form-field textarea::placeholder {
                color: $colorWhite;
            }

            .legal-consent-container {
                margin-top: 3.5rem;
                color: black;
                font-family: 'DMSans-Regular';
            }

            .inputs-list {
                list-style: none;
                padding: 0;
            }

            .hs-form-booleancheckbox-display {
                width: 100%;
                display: flex;
                align-items: center;

                input[type='checkbox'] {
                    accent-color: $securityBgColor !important;
                    width: 28px !important;
                    height: 28px;
                }
            }

            .hs_submit input {
                background: $primaryRedColor;
                border-radius: 4px;
                color: $colorWhite;
                border-color: $primaryRedColor;
                padding: 12px 60px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
                line-height: 15px;
                margin-top: 20px;
            }
        }

        .nav-item .nav-link {
            padding-top: 40px;
        }
    }


    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
        color: var(--bs-nav-tabs-link-active-color);
        background-color: transparent;
        border: 0;
    }

    .tab-pane.active {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }

    .nav-link.active {
        background-color: $secondaryPinkColor;
    }

    .nav-tabs {
        border-bottom: 0;
        background: transparent;

    }

    .tab-content {
        background-color: $secondaryPinkColor;
    }

    .nav-tabs .nav-item {
        padding: 0;
        margin-right: 0
    }



    // @media (max-width: $breakpointLgTab) {
    //     .contact-form-container {
    //         margin-top: 6rem;

    //         .hubspot-form {
    //             margin-top: 3rem;
    //         }
    //     }
    // }

    // @media (max-width: $breakpointSmTab) {
    //     .contact-tile-container {
    //         padding-top: 4.5rem;
    //     }

    //     .contact-form-container {
    //         padding: 2.8rem;

    //         .hubspot-form {

    //             .hs-form-field:not(.hs-fieldtype-booleancheckbox) input,
    //             .hs-form-field textarea {
    //                 width: 100% !important;
    //             }
    //         }
    //     }

    //     .form-title {
    //         text-align: center;
    //     }
    // }

    // @media (max-width: $breakpointLgMobile) {
    //     .contact-form-container {
    //         margin-top: 3rem;
    //         .form-title {
    //             font-size: 16px;
    //             margin-top: 0;
    //         }
    //         .hubspot-form {
    //             margin-top: 25px;
    //         }
    //         .hs-form-field input:-webkit-autofill,
    //         .hs-form-field input:-webkit-autofill:hover,
    //         .hs-form-field input:-webkit-autofill:focus,
    //         .hs-form-field textarea:-webkit-autofill,
    //         .hs-form-field textarea:-webkit-autofill:hover,
    //         .hs-form-field textarea:-webkit-autofill:focus,
    //         .hs-form-field input,
    //         .hs-form-field textarea {
    //             padding: 14px 13px !important;
    //         }
    //     }
    //     .contact-tile-container {
    //         .contact-title {
    //             font-size: 42px;
    //         }

    //         .contact-subtitle {
    //             font-size: 12px;
    //         }
    //     }
    //     .footer p {
    //         font-size: 8px;
    //     }
    // }
    @media (min-width: $midSmallDevice) and (max-width: $maxMediumDevice) {
        .contact-form-container {
            padding-bottom: 0;

            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 50px;
                margin-right: 50px;
            }

            .tab-pane {
                padding-bottom: 3.625rem;
            }
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .contact-tile-container {
            .contact-subtitle {
                width: 80%;
            }
        }

        .contact-form-container {
            padding-bottom: 0;

            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 50px;
                margin-right: 50px;
            }

            .tab-pane {
                padding-bottom: 3.625rem;
            }
        }

        .glc-help-center {
            margin-bottom: 100px;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .contact-tile-container {
            margin-bottom: 0px;
            .contact-subtitle {
                width: 82%;
            }
        }

        .contact-form-container {
            padding-bottom: 0;
            margin-bottom: 5rem;
            .nav-item {
                .nav-link {
                    padding: 13px;

                    h4 {
                        font-size: 8px;
                    }
                }
            }


            .hubspot-form {
                margin-top: 2.25rem;
                margin-left: 28px;
                margin-right: 28px;

                .hs-form-field .hs-input {
                    width: 100% !important;
                }
                .hs-form-booleancheckbox-display input {

                }
            }

            .tab-pane {
                padding-top: 3.5rem;
                padding-bottom: 3.625rem;

                h3 {
                    font-size: 16px;
                }
            }
        }

        .glc-help-center {
            margin-bottom: 70px;
        }
    }
}