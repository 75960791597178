@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.physical-layer-security-wrapper {
    .hero-content {
        h1 {
            div {
                margin-top: -1rem;
            }
        }

        h5 {
            max-width: 54.375rem;
            margin: auto;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    .hero-bg {
        background: url("../../../public/solutions/physical-layer-security-assets/hero-bg.png") no-repeat;
        background-size: contain;
    }

    .solution-features {
        padding-top: 5.5rem;
        padding-bottom: 4.125rem;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;

        h5 {
            max-width: 59.937rem;
            margin-top: 1.25rem;
            margin-bottom: 5.375rem;
            text-align: center;
        }

        .solution-features-elements {
            height: 16.875rem;
            padding: 2.125rem;
            padding-right: 1rem;
            border: 0.125rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }
    }

    .secure-intelligent-layer-wrapper {
        padding-top: 181px;
        padding-bottom: 100px;

        h2 {
            max-width: 39rem;
            margin-bottom: 2.375rem;
        }

        h5 {
            width: 90%;
        }
    }

    .solution-info-header {
        padding-top: 9.375rem;
        padding-bottom: 6.75rem;
    }

    .video-wrapper {
        padding-top: 8.5rem;
        padding-bottom: 8.5rem;

        h2 {
            margin-bottom: 2.937rem;
        }

        // h5 {
        //     margin-bottom: 8.687rem;
        // }
    }

    @media (min-width: $midSmallDevice) {
        .security-features {
            .features-accordion-container {
                min-height: 590px;
            }
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .hero-content h5 {
            width: 60%;
        }

        .solution-features h5 {
            width: 63%;
        }

        .solution-features {
            padding-top: 61px;
            padding-bottom: 61px;
        }

        .solution-info-header {
            padding-top: 74px;
            padding-bottom: 74px;
        }

        .video-wrapper {
            padding-top: 6.875rem;
            padding-bottom: 6.875rem;
        }

        .divider-height {
            height: 1px;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .hero-content h5 {
            width: 70%;
            margin-top: 25px;
            margin-bottom: 50px;
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .solution-features h5 {
            width: 71%;
            margin-top: 22px;
            margin-bottom: 22px;
        }

        .solution-info-header {
            padding-top: 65px;
            padding-bottom: 65px;
        }

        .video-wrapper {
            padding-top: 6.25rem;
            padding-bottom: 6.25rem;
        }

        .secure-intelligent-layer-wrapper {
            padding-top: 100px;
            padding-bottom: 0;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            height: 63vh;
            h5 {
            width: 63%;
            margin-top: 35px;
            margin-bottom: 57px;
        }
    }

        .solution-features {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        .solution-features h5 {
            width: 77%;
            margin-top: 22px;
            margin-bottom: 22px;
        }

        .secure-intelligent-layer-wrapper {
            padding-top: 90px;
            padding-bottom: 0;
        }

        .solution-info-header {
            padding-top: 55px;
            padding-bottom: 55px;
        }

        .video-wrapper {
            padding-top: 84px;
            padding-bottom: 84px;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .hero-content{
            height: 70vh;
            h5 {
                width: 80%;
            }


        }
        .solution-features {
            padding-top: 52px;
            padding-bottom: 52px;
        }

        .solution-features h5 {
            width: 80%;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .secure-intelligent-layer-wrapper {
            padding-top: 85px;
            padding-bottom: 0;
        }

        .solution-info-header {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .video-wrapper {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-bg {
            background-size: cover;
            background-position: center;
        }

        .hero-content {
            h1 {
                div {
                    margin-top: 0;
                }
            }

            h5 {
                width: 80%;
                margin: auto;
                text-align: center;
                margin-top: 3rem;
                margin-bottom: 3rem;
            }
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            h5 {
                width: 87%;
                margin-top: 35px;
                margin-bottom: 10px;
            }
        }

        .secure-intelligent-layer-wrapper {
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;

            h2 {
                width: 100%;
            }

            h5 {
                margin: auto;
                margin-bottom: 65px;
            }

            .physical-layer-security-image {
                display: none;
            }
        }

        .solution-info-header {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .solution-diagram {
            content: url("../../../public/solutions/physical-layer-security-assets/how-it-works-mobile.svg");
        }

    }
}