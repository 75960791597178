@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

#opx-wrapper {
    .opx-hero-content {
        height: 70vh;
        padding-top: 16.125rem;

        h1 {
            z-index: 1;
            position: relative;
        }

        .opx-hero-image {
            width: 65%;
            margin-top: 4.5rem;
            position: relative;
            z-index: 1;
        }

        .opx-hero-gradient {
            width: 54.438rem;
            height: 20.938rem;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            filter: blur(12.5rem);
            margin: auto;
            position: absolute;
        }
    }

    .opx-hero-text {
        line-height: 120%;
        width: 90%;
        margin: auto;
    }

    .opx-info-text {
        width: 37%;
        line-height: 126%;
    }

    .opx-info-width {
        width: 71%;
        margin: auto;
    }

    .product-header {
        padding-bottom: 28.938rem;
        padding-top: 2.188rem;

        .opx-header-bottom-gradient {
            width: 28.125rem;
            height: 20.125rem;
            right: 0;
            bottom: 0;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            filter: blur(12.5rem);
            margin: auto;
            position: absolute;
        }
    }

    .dynamic-and-secure-explanation,
    .dynamic-and-secure-explanation span {
        padding-top: 31px;
    }

    .dynamic-and-secure-explanation span {
        font-weight: 700;
    }

    .dynamic-image-container {
        padding-right: 2rem;
    }

    // .network-infrastructure-explanation,
    // .network-infrastructure-explanation span {
    //     font-size: 1.75rem;
    //     line-height: 150%;
    //     font-style: normal;
    //     font-weight: 400;
    // }
    // .network-infrastructure-explanation span {
    //     font-weight: 700;
    // }
    .product-info {
        padding-top: 100px;
        padding-bottom: 100px;
        border: 0.125rem solid;
        border-image-source: linear-gradient(to left, rgb(73, 200, 73), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        width: 80%;
        height: fit-content;
        margin-top: -21.313rem;
        margin-bottom: 16.875rem;
    }

    .opx-connectivity-heading {
        padding-top: 10.563rem;
        padding-bottom: 6.438rem;
    }

    .connectivity-explanation,
    .connectivity-explanation span {
        text-align: center;
        padding-top: 4.25rem;
        padding-bottom: 7.375rem;
        width: 70%;
    }

    .section-background {
        background: url("../../../public/products/opx-white-background.svg") no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        padding-bottom: 8rem;
    }

    .opx-info-section {
        padding-top: 32rem;
    }

    .product-info-header {
        padding-top: 10rem;
        padding-bottom: 10rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    .slide-item img {
        max-width: 80%;

    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .opx-info-section {
            padding-top: 28rem;
            // padding-bottom: 2rem;
        }

        .opx-info-width {
            width: 80%;
        }

        .opx-hero-text {
            width: 100%;
        }

        .opx-info-text {
            width: 40%;
        }

        .view-360 {
            width: 80% !important;
        }

        .connectivity-explanation {
            width: 82%;
        }

        .product-info-header {
            padding-top: 8rem;
            padding-bottom: 8rem;

            h2 {
                padding-top: 4.563rem;
                line-height: 124%;

                div {
                    -webkit-text-stroke-width: 0.063rem;
                    -webkit-text-stroke-color: $primaryGreenColor;
                    font-size: 150px;
                    line-height: 124%;
                    color: transparent;
                }
            }
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .product-info {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .opx-info-section {
            padding-top: 25rem;
            padding-bottom: 2rem;
        }

        .dynamic-and-secure-explanation {
            padding-top: 0;
        }

        .opx-info-section {
            padding-top: 20rem;
        }

        .view-360 {
            width: 80% !important;
        }

        .opx-connectivity-heading {
            padding-top: 6rem;
            padding-bottom: 2rem;
        }

        .connectivity-explanation {
            padding-top: 2rem;
            padding-bottom: 4rem;
            width: 80%;
        }

        .product-info-header {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }

        .product-info-header img {
            width: 80%;
        }
        .section-background{
            padding-bottom: 2rem;
        }
    }


    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .product-info {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .dynamic-and-secure-explanation {
            padding-top: 0;
        }

        .opx-info-section {
            padding-top: 20rem;
        }

        .view-360 {
            width: 80% !important;
        }

        .opx-connectivity-heading {
            padding-top: 4rem;
            padding-bottom: 0rem;
        }

        .connectivity-explanation {
            padding-top: 0;
            padding-bottom: 4rem;
            width: 90%;
        }

        .product-info-header {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .product-info-header h2 div {
            font-size: 110px;
            line-height: 124%;
        }

        .product-info-header img {
            width: 70%;
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .opx-hero-content {
            height: 60vh;
        }

        .product-info {
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;
        }

        .opx-info-text {
            width: 100%;
        }

        .dynamic-and-secure-explanation {
            padding-top: 16px;
            padding-bottom: 35px;
            width: 80%;
            margin: auto;
        }

        .opx-info-section {
            padding-top: 20rem;
            padding-bottom: 4rem;
        }

        .section-background {
            padding-bottom: 0;
        }

        .opx-info-width {
            width: 90%;
            margin: auto;
        }

        .opx-connectivity-heading {
            padding-top: 100px;
            padding-bottom: 0;
        }

        .view-360 {
            width: 70% !important;
        }

        .connectivity-explanation {
            padding-top: 0;
            padding-bottom: 4rem;
            width: 88%;

        }

        .product-info-header {
            padding-top: 5rem;
            padding-bottom: 5rem;

            img {
                width: 70%;

            }

            h2 div {
                font-size: 92px;
            }

            h2 {
                padding-top: 30px;
            }

        }

    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .opx-hero-content {
            .opx-hero-gradient {
                width: 290px;
                height: 290px;
                filter: blur(73px);
            }



            .opx-hero-image {
                width: 90%;
                margin-top: 35px;
                margin-bottom: 50px;
            }
        }
        .view-360 {
            height: 125px !important;
        }

        .opx-hero-text {
            line-height: 110%;
            width: 93%;
        }

        .product-info {
            width: 100%;
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;

            .row {
                margin-left: 0;
                margin-right: 0;
            }

        }

        .opx-info-text {
            width: 100%;
            margin: auto;
        }
        .opx-info-width{
            width: 100%;
        }

        .dynamic-image {
            width: 80%;
        }

        .dynamic-image-container img {
            display: none;
        }

        .dynamic-and-secure-explanation,
        #opx-wrapper .dynamic-and-secure-explanation span {
            padding-top: 16px;
            width: 90%;
            margin: auto;
        }

        .section-background {
            background-size: contain;
            padding-bottom: 1rem;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .opx-info-section {
            padding-top: 17rem;
            padding-bottom: 2rem;
        }

        .opx-connectivity-heading {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .connectivity-explanation,
        .connectivity-explanation span {
            text-align: center;
            padding-top: 0;
            padding-bottom: 50px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 100%;
        }

        .opx-form-factor-image {
            width: 85%;
        }

        .product-info-header {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                padding-top: 30px;

                div {
                    font-size: 40px;
                }
            }
        }
    }

}