@import "../../sassStyles/colors";
@import "../../sassStyles/variables";


.footer {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    // margin-top: 7.688rem;
    height: 300px;
    text-align: center;
    padding-top: 30px;

    .footer-logo {
      margin-bottom: 41px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 28px;
      line-height: 170%;
      text-align: center;
    //   opacity: 50%;
      width: 50%;
    }

  }


  .legal {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    opacity: 0.6;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    //height: 80px;
    align-items: center;
    bottom: 0;
  }

  .social-links {
    display: inline-block;

    a {
      margin-right: 0.6875rem;
    }
  }



  .footer-pipe-list {
    overflow: hidden;
    opacity: 0.7;
  }

  .footer-pipe-list ul {
    list-style: none;
    padding: 0;
    margin-left: -4px;
  }

  .footer-pipe-list ul li {
    display: inline;
    white-space: nowrap;
  }

  .footer-pipe-list ul li:before {
    content: " | ";
  }

  .hr-footer {
    opacity: 0.1;
    border: 1px solid black;
  }

  footer {
    background-color: black;
    z-index: 2;
    position: relative;
  }

  .link,
  link:hover {
    color: black !important;
    text-decoration: none;
  }
  .footer-links{
    gap: 100px;
    font-weight: 600;
    margin-top: 20px;
  }


//   @media (max-width: $breakpointLgMobile) {
//     .footer-text, .link {
//       font-size: 10px;
//     }
//     .legal {
//       flex-direction: column;
//     }
//   }

.footer-wrapper {
    position: relative;
    z-index: 1;

    .footer-list {
        list-style: none;
        padding-left: 0;
    }

    .footer-item {
        font-size: 0.938rem;
        line-height: 130%;

        .link {
            color: $footerTextColor;
        }
    }

    .footer-heading-font {
        font-size: 1.375rem;
    }

    .footer-padding {
        padding-top: 5rem;

    }

    .footer-logo {
        margin-bottom: 2.438rem;
    }

    .footer-contact-heading {
        font-size: 2.5rem;
        line-height: 120%;
    }

    .footer-text {
        font-size: 0.938rem;
    }

    .footer p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 150%;
        text-align: left;
        max-width: 231px;
    }

    .footer .footer-contact-info {
        font-size: 1rem;
    }

    .company-links {
        /* padding-left: 100px; */
    }

    .legal {
        display: flex;
        flex-direction: row;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        align-items: center;
        bottom: 0;

        .social-links {
            display: inline-block;

            a {
                margin-right: 1.145rem;
            }

        }

        .social-links:hover {
            color: $hoverBtnColor !important;
        }
    }

    .footer-pipe-list ul {
        list-style: none;
        padding: 0;
        margin-left: -4px;
    }

    .footer-pipe-list ul li {
        display: inline;
        white-space: nowrap;
    }

    .footer-pipe-list ul li:before {
        content: " | ";
    }

    .hr-footer {
        opacity: 0.1;
        border: 1px solid $colorWhite;
    }

    .items-list {
        padding-bottom: 4rem;
    }

    .link,
    link:hover {
        text-decoration: none;
    }

    .link:hover {
        color: $primaryGreenColor;
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {

        .footer-contact-heading {
            font-size: 38px;
        }

        .footer-contact-info {
            width: 100%;
        }

        .footer .footer-contact-info {
            font-size: 1rem;
            width: 100%;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .footer p {
            /* width: 86%; */
        }

        .footer-padding {
            padding-top: 4.375rem;
        }

        .footer-logo {
            margin-bottom: 26px;
            width: 50%;

        }

        .footer-item {
            font-size: 0.8rem;

        }

        .footer-heading-font {
            font-size: 18px;
        }

        .footer-contact-heading {
            font-size: 32px;
        }

        .footer .footer-contact-info {
            font-size: 0.75rem;
            width: 100%;
        }

        .items-list {
            margin-bottom: 3.0rem;
        }
        .legal span{
            font-size: 10px;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .footer-logo {
            max-width: 126px;
        }

        .footer-item {
            font-size: 0.625rem;
        }

        .footer-heading-font {
            font-size: 0.812rem;
        }

        .footer-contact-heading {
            font-size: 1.5rem;
        }

        .footer p {
            font-size: 0.687rem;
        }

        .footer-contact-info {
            font-size: 0.625rem !important;
        }

        .footer .footer-contact-info {
            width: 100%;
        }

        .legal {

            .footer-text,
            .link {
                font-size: 0.75rem;
            }
        }

        .company-links {
            padding-left: 0;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
        .footer p {
            width: 100%;
        }

        .footer .footer-contact-info {
            font-size: 1rem;
            width: 100%;
        }

        .items-list {
            margin-top: 70px;
        }

        .legal {

            .footer-text,
            .link {
                font-size: 0.75rem;
            }
        }

        .company-links {
            padding-left: 0;
        }


        .footer-container {
            flex-wrap: wrap;

            .six-col {
                width: 50%;
            }

            .four-col {
                width: 33.3333333%;
            }
        }

    }

    @media (max-width: $maxTabDevice) {
        .tablet-contact {
            display: block !important;
        }

        .tablet-bottom-contact {
            display: none;
        }

        .items-list {
            margin-top: 50px;
            margin-bottom: 0;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .tablet-bottom-contact {
            text-align: center;
            margin-bottom: 43px;
        }

        .footer-padding {
            padding-top: 30px;
        }

        .items-list {
            padding-bottom: 0;
        }

        .footer p {
            width: 100%;
        }

        .footer-logo {
            margin-bottom: 31px;
        }

        .legal {
            flex-direction: column;
        }

        .footer .footer-contact-info {
            font-size: 1rem;
            width: 65%;
            margin-bottom: 1rem;
        }

        .footer-contact-heading {
            margin-bottom: 1rem !important;
        }
        .footer-container {
            flex-wrap: wrap;

            .six-col, .four-col {
                width: 100%;
            }
        }
        .tablet-contact {
            margin-top: 50px;
        }
    }

}