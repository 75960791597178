@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.more-products-container {
    padding-bottom: 10.875rem;
    .more-products-heading {
        padding-bottom: 4.188rem;
        padding-top: 4.188rem;
    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: $primaryGreenColor;
    }
    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 5rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0.938rem;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: '-';
        text-align: center;
        opacity: .25;
        color: #FFFFFF;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .slide-item {
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));
        border: 0.06rem solid;
        border-image-source: linear-gradient(to left, rgb(73, 200, 73), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        border-radius: 0.25rem;
        width: 80% !important;
        margin: auto;
        padding-top: 6.75rem;
        padding-bottom: 6.75rem;
        padding-left: 4.375rem;
        display: block !important;
    }
    .more-products-gradient{
        position: absolute;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        width: 200px;
        height: 200px;
        filter: blur(9rem);
        right: 22%;
        bottom: 10%;
        top: 10%;
        margin: auto;
    }
    .slide-item{
        img{
            max-width: 80%;
        }

    }
    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .slide-item img {
            max-width: 70%;

        }

    }
    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .slide-item img {
            max-width: 70%;

        }
    }
    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .slide-item{
            width:100% !important;
            padding-top: 80px;
            padding-bottom: 80px;
            padding-left: 30px;
        }
        .slide-item img {
            max-width: 70%;

        }
    }
    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .slide-item{
            width: 100% !important;
            padding: 2rem;
            text-align: center;
            img{
                display: none;
            }
            p{
                width: 60%;
                margin: auto;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
        .more-products-gradient{
            display: none;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .more-products-heading {
            padding-bottom: 50px;
            padding-top: 50px;
            width: 85%;
            margin: auto;
            line-height: 110%;
        }
        .slide-item{
            width: 100% !important;
            padding: 30px;
            text-align: center;
            img{
                display: none;
            }
        }
        .more-products-gradient{
            display: none;
        }
    }
}
