@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.datacenter-colocation-wrapper {
    .hero-content {
        .hero-top-right-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 21.188rem;
            height: 21.188rem;
            filter: blur(12.5rem);
            z-index: 1;
            right: 20%;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        h1 {
            z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -1rem;

            div {
                margin-top: -1rem;
            }
        }

        h5 {
            max-width: 77.875rem;
            position: relative;
            z-index: 3;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 5.625rem;
        }

        .hero-heading-bg {
            // background: url("../../../public/solutions/datacenter-colocation-assets/hero-bg.svg") no-repeat;
            height: 15.125rem;
            width: 50rem;
            text-align: center;
            position: relative;
        }
    }

    .solution-features {
        padding-top: 8.375rem;
        padding-bottom: 8.375rem;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;

        h5 {
            max-width: 77.875rem;
            margin-top: 2.563rem;
            margin-bottom: 5.375rem;
            text-align: center;
        }

        .solution-features-elements {
            height: 16.875rem;
            padding: 2.125rem;
            border: 0.06rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }
    }

    .network-visibility-wrapper {
        padding-top: 12.125rem;
        padding-bottom: 6rem;

        .network-bg {
            background: url("../../../public/solutions/datacenter-colocation-assets/network-visibility.png") no-repeat;
            background-size: cover;
            text-align: center;
            width: 100%;
            padding-top: 7.25rem;
            padding-bottom: 7.25rem;

            /* h2 {
                padding-top: 15.625rem;
            } */

            h5 {
                max-width: 77rem;
                margin: auto;
                margin-top: 1.8125rem;
            }
        }
    }

    .solution-info-header {
        padding-top: 9.375rem;
        padding-bottom: 9.375rem;
    }
    @media (min-width: $midSmallDevice) {
        .datacenter-features {
            .features-accordion-container {
                min-height: 630px;
            }
        }
    }
    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .network-visibility-wrapper {
            padding-top: 110px;
            padding-bottom: 110px;

            .network-bg {
                h5 {
                    max-width: 67rem;
                    margin: auto;
                    margin-top: 26px;
                }
            }
        }

        .hero-content h5 {
            width: 65%;
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 5.125rem;

            .container {
                padding-left: 75px;
                padding-right: 75px;
            }

            .solution-features-elements {
                margin-bottom: 30px;
            }

            h5 {
                width: 68%;
            }
        }

        .solution-info-header {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .divider-height {
            height: 2px;
        }

        .stroke-text {
            font-size: 105px;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .network-visibility-wrapper {
            padding-top: 8rem;
            padding-bottom: 8rem;

            .network-bg {
                h5 {
                    width: 85%;
                    margin: auto;
                    margin-top: 20px;
                }
            }
        }

        .hero-content h5 {
            width: 73%;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .solution-features {
            padding-top: 100px;
            padding-bottom: 100px;

            h5 {
                margin-top: 50px;
                margin-bottom: 50px;
            }

            .col-xl-3,
            .col-lg-3 {
                margin-bottom: 0 !important;
            }


            .container {
                padding-left: 50px;
                padding-right: 50px;
            }

            .solution-features-elements {
                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

            h5 {
                width: 68%;
            }

            .solution-features-elements {
                height: 180px;
                padding: 1.75rem;
            }
        }

        .solution-info-header {
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {

        .hero-content {
            height: 70vh;

            h5 {
                width: 76%;
                margin-top: 50px;
                margin-bottom: 50px;
            }


        }

        .solution-features {
            padding-top: 60px;
            padding-bottom: 60px;

            h5 {
                margin-top: 50px;
                margin-bottom: 50px;
                width: 80%;
            }

            .container {
                padding-left: 40px !important;
                padding-right: 40px !important;
            }

            .solution-features-elements {
                height: 160px;
                padding: 1rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 1.75rem;
                    width: 1.75rem;
                }
            }
        }

        .network-visibility-wrapper {
            padding-top: 120px;
            padding-bottom: 120px;

            .network-bg {
                padding-top: 80px;
                padding-bottom: 80px;

                h5 {
                    width: 82%;
                    margin: auto;
                    padding-top: 12px;
                }
            }
        }

        .solution-info-header {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .stroke-text {
            font-size: 78px;
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        #hero-bg-vector {
            width: 75% !important;
        }

        .network-visibility-wrapper {
            padding-top: 70px;
            padding-bottom: 70px;

            .network-bg {
                padding-top: 60px;
                padding-bottom: 60px;

                h5 {
                    width: 90%;
                    margin: auto;
                    padding-top: 9px;
                }
            }
        }

        .hero-content {
            height: 65vh;

            h5 {
                width: 73%;
                margin-top: 25px;
                margin-bottom: 25px;
            }

        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            .container {
                padding-left: 72px !important;
                padding-right: 72px !important;
            }

            h5 {
                width: 75%;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;
                margin-bottom: 2rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .network-visibility-wrapper {
            padding-top: 50px;
            padding-bottom: 50px;

            .network-bg {
                background: url("");
                padding-top: 0;
                padding-bottom: 0;

                h2 {
                    padding-top: 0;

                }

                h5 {
                    width: 83%;
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }
        }

        .hero-content {
            h1 {
                bottom: -1rem;
            }

            #hero-bg-vector {
                width: 40% !important;
            }

            h5 {
                width: 92%;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        .hero-content h1 div {
            margin-top: 0;
        }

        .solution-features-container {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-features {
            border-radius: 0;
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;


            h5 {
                width: 86%;
                margin-top: 25px;
                margin-bottom: 50px;
            }

            .container {
                padding-left: 50px !important;
                padding-right: 50px !important;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;
                margin-bottom: 2rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }

        .solution-diagram {
            content: url("../../../public/solutions/datacenter-colocation-assets/how-it-works-mobile.svg");
        }
    }
}