@import "../../sassStyles/variables";
@import "../../sassStyles/colors";

.glc-help-center {
    // margin-top: 250px;
    margin-bottom: 150px;
}

.help-element {
    background: $secondaryPinkColor;
    // filter: drop-shadow(0px 5.34929px 53.4929px rgba(0, 0, 0, 0.12));
    border-radius: 8px;
    $border-color: $bgPurpleColor;
    padding-top: 30px;
    height: 245px;
    padding-left: 42px;
    // border: 0.06rem solid;


}

.ticket-info-text {
    margin-top: 86px;
    width: 40%;

}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    // .help-element img {
    //     width: 9%;
    // }

}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {

    .help-element img {
        width: 10%;
    }

    .help-element {
        padding-top: 20px;
        height: 200px;
        padding-left: 20px;
    }

}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
    .help-element img {
        width: 10%;
    }

    .help-element {
        padding-top: 20px;
        height: 160px;
        padding-left: 20px;
    }
}
@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
    .help-element img {
        width: 12%;
    }
    .help-element {
        padding-top: 20px;
        height: 160px;
        padding-left: 15px;
    }
}
@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .glc-help-center
    {
        margin-bottom: 0;
        .row{
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;

        }

    }
    .help-element{
        text-align: center;
        height: 180px;
        margin-bottom: 60px;
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        img{
            width: 12%;
        }

    }

}