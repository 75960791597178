@import "colors";
@import "fonts";
@import "variables";

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bodybackgroundColor;
}

.hero-content {
  height: 100vh;
}
.view-apd-btn img {
  width: auto !important;
}

//Typography
h1 {
  font-size: 5rem;
  line-height: 120%;
  margin: 0;

  span {
    font-size: 5rem;
  }
  .registered-R{
    font-size: 0.5em;
    top: -0.7em;
    left: -0.1em;

  }
}

h2 {
  font-size: 3.75rem;
  line-height: 120%;

  span {
    font-size: 3.75rem;

  }
  .registered-R{
    font-size: 0.5em;
    top: -0.7em;
    left: -0.1em;
    position: relative;

  }
}

h3 {
  font-size: 2.5rem;
  line-height: 120%;

  span {
    font-size: 2.5rem;
    line-height: 120%;
  }
  .registered-R{
    font-size: 0.7em;

  }
}

h4 {
  font-size: 1.75rem;
  line-height: 150%;

  span {
    font-size: 1.75rem;
  }
}

h5 {
  font-size: 1.75rem;
  line-height: 150%;

  span {
    font-size: 1.75rem;
  }
  .registered-R{
    font-size: 0.6em;
    top: -0.7em;
    left: -0.1em;
    position: relative;

  }
}

h6 {
  font-size: 1.25rem;
  line-height: 120%;

  span {
    font-size: 1.25rem;
  }
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

span {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

//Text Colors

.text-primary-green {
  color: $primaryGreenColor;
}
.text-primary-red {
  color: $primaryRedColor;
}

.text-secondary-white {
  color: $secondaryWhiteColor;
}

.text-white {
  color: $colorWhite;
}
.text-purple{
  color: $bgPurpleColor;
}


.text-green {
  color: $secondaryBtnTextColor;
}

.text-footer-color {
  color: $footerTextColor;
}

//Text Alignment

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Text Gradient
.text-gradient {
  background: $textGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

//Section Description

.section-description {
  font-weight: 400;
  font-size: 2.375rem;
  line-height: 116.5%;

  span {
    font-size: 2.375rem;
  }
}

//Z Indexes

.z-index-1 {
  z-index: 1;
  position: relative;
  width: 80%;
  margin: auto;
}
.z-index-2 {
  z-index: 2;
}

//Background Colors

.bg-blue {
  background-color: $bodybackgroundColor;
}

.bg-white {
  background-color: $colorWhite;
}

.bg-demo {
  background-color: $demobackgroundColor;
}

.bg-blog {
  background-color: $primaryBtnColor;
}

.bg-features {
  background-color: $featuresbackgroundColor;
}

.bg-support {
  background-color: $bgSupport;
}

.bg-black {
  background-color: #000000;
}
.bg-pink{
  background-color: $secondaryPinkColor;
}
.bg-purple {
  background-color: $bgPurpleColor;
}

.badge {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  background-color: $primaryRedColor;
  border-radius: 0.25em;
  margin-bottom: 1rem;
  text-transform: uppercase;
  min-width: 4.375rem;
}

//Button Styling and States

.btn {
  background-color: $primaryRedColor;

}

.btn.secondary-btn {
  background-color: $btnSecondaryBg;
  border-color: $btnSecondaryBg;
}
.btn.secondary-light-border{
  border-color : $colorWhite;
}

.btn.secondary-btn:hover {
  background-color: $primaryRedColor;
  color: $hoverBtnColor;
}

.btn:hover {
  background-color: $secondaryWhiteColor;
  border-color: $primaryRedColor;
}
.primary-btn:hover{
  color: $primaryRedColor !important;
}

.primary-btn {
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: auto;
  line-height: 1.28rem;
  border-radius: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-decoration: none;
  font-family: 'DMSans-Regular';
}

.secondary-btn {
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: auto;
  line-height: 1.28rem;
  border-radius: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-decoration: none;
  border: 1px solid;
  font-family: 'DMSans-Regular';
}

.sup{
  font-size: 0.6em;

}
.sensus-trademark{
  font-size: .4em !important;
  top: -0.8em;
  left: -0.3em;
}
.sensus-heading-trademark {
  font-size: 1.2rem;
  top: -1.3em;
  left: -0.5em;
}
//Labels
.label{
  border-radius: 100px;
  background-color: $featuresbackgroundColor;
  max-width: 140px;
  margin: auto;
  margin-bottom: 40px;
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {

  .primary-btn, .secondary-btn {
    min-width: 243px;
    height: auto;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0 !important;
  }
}

//Solutions Diagram Colors

.security-color {
  background: $securityBgColor;
}

.capacity-color {
  background: $capacityBgColor;
}

.diagnostics-color {
  background: $diagnosticsBgColor;
}

.universal-breakout-color {
  background: $breakoutBgColor;
}

.documentation-color {
  background: $documentationBgColor;
}
.text-url{
  text-decoration: none;
  color: $primaryRedColor;
}
.text-url:hover{
  color: $btnSecondaryBg;
}


// Border Colors
.border-secondary-color {
  border-color: $borderSecondayBtn;
}

.border-secondary-color:hover {
  border-color: $hoverBtnColor;
}

//Media Queries
//Container
@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%
  }

  .container, .container-lg {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
    max-width: 1920px;
  }

}

@media (min-width: $minSmallDevice) and (max-width: 992px) {
  // .container {
  //   padding-left: 72px !important;
  //   padding-right: 72px !important;
  // }
}

//Positions
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

//Font Weights

.font-weight-700 {
  font-weight: 700;
}
.font-weight-600 {
  font-weight: 600;
}

//widths
.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-90 {
  width: 90%;
}


@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
  h1 {
    font-size: 5rem;
    line-height: 120%;
    margin: 0;

    span {
      font-size: 5rem;
    }
  }

  h2 {
    font-size: 3.125rem;
    line-height: 120%;

    span {
      font-size: 3.125rem;
    }
  }

  h3 {
    font-size: 2.375rem;
    line-height: 1.2rem;

    span {
      font-size: 2.375rem;
    }
  }

  h4 {
    font-size: 1.438rem;
    line-height: 150%;

    span {
      font-size: 1.438rem;
    }
  }

  h5 {
    font-size: 1.313rem;
    line-height: 150%;

    span {
      font-size: 1.313rem;
    }
  }

  h6 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

}


@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  h1 {
    font-size: 4.375rem;
    line-height: 120%;
    margin: 0;

    span {
      font-size: 4.375rem;
    }
  }

  h2 {
    font-size: 2.625rem;
    line-height: 120%;

    span {
      font-size: 2.625rem;
    }
  }

  h3 {
    font-size: 2.188rem;
    line-height: 120%;

    span {
      font-size: 2.188rem;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 1.25rem;
    line-height: 150%;

    span {
      font-size: 1.25rem;
    }
  }

  h5 {
    font-size: 1.25rem;
    line-height: 150%;

    span {
      font-size: 1.25rem;
    }
  }

  h6 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  h1 {
    font-size: 3.75rem;
    line-height: 120%;
    margin: 0;

    span {
      font-size: 3.75rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    line-height: 120%;

    span {
      font-size: 2.5rem;
    }
  }

  h3 {
    font-size: 1.625rem;
    line-height: 120%;

    span {
      font-size: 1.625rem;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 150%;

    span {
      font-size: 1.125rem;
    }
  }

  h5 {
    font-size: 0.938rem;
    line-height: 150%;

    span {
      font-size: 0.938rem;
    }
  }

  h6 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

}

@media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
  h1 {
    font-size: 3.125rem;
    line-height: 120%;
    margin: 0;

    span {
      font-size: 3.125rem;
    }
  }

  h2 {
    font-size: 1.875rem;
    line-height: 120%;

    span {
      font-size: 1.875rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    line-height: 120%;

    span {
      font-size: 1.5rem;
      line-height: 120%;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 150%;

    span {
      font-size: 1.125rem;
    }
  }

  h5 {
    font-size: 0.875rem;
    line-height: 150%;

    span {
      font-size: 0.875rem;
    }
  }

  h6 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  h1 {
    font-size: 2.5rem;
    line-height: 110%;
    margin: 0;

    span {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: 2.188rem;
    line-height: 110%;

    span {
      font-size: 2.188rem;
    }
  }

  h3 {
    font-size: 1.875rem;
    line-height: 110%;

    span {
      font-size: 1.875rem;
      line-height: 110%;
    }
  }

  h4 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  h5 {
    font-size: 0.813rem;
    line-height: 150%;

    span {
      font-size: 0.813rem;
      line-height: 150%;
    }
  }

  h6 {
    font-size: 1.25rem;
    line-height: 120%;

    span {
      font-size: 1.25rem;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  .container{
    padding-left: 30px;
    padding-right: 30px;
  }
}