@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
.video-wrapper {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
}
.stroke-text {
    -webkit-text-stroke-width: 0.063rem;
    -webkit-text-stroke-color: $primaryGreenColor;
    font-size: 9rem;
    line-height: 124%;
    color: transparent;
}
.solution-diagram{
    margin-top: 50px;
    margin-bottom: 100px;
    max-height: 750px;
}
.diagram-caption{
    width: 50%;
    margin: auto;
}
.solution-info-header {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
}
@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .stroke-text {
        font-size: 120px;
    }
    .video-wrapper {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .solution-info-header {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
    }
}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
    .stroke-text {
        font-size: 82px;
    }
    .stroke-icon{
        width: 20%;
    }
    .video-wrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .solution-info-header {
        padding-top: 75px;
        padding-bottom: 75px;
    }

}
@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
    .stroke-text {
        font-size: 100px;
    }
    .stroke-icon{
        width: 20%;
    }
    .video-wrapper, .solution-info-header {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }
    .divider-height{
        height: 1px;
    }
}
@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
    .divider-height{
        height: 1px;
    }
    .stroke-text {
        font-size: 61px;
    }
    .stroke-icon{
        width: 16%;
    }
    .video-wrapper {
        padding-top: 6.438rem;
        padding-bottom: 6.438rem;
    }
    .solution-info-header {
        padding-top: 45px;
        padding-bottom: 45px;
    }
}
@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .divider-height{
        height: 1px;
    }
    .diagram-caption {
        width: 90%;
    }
    .video-wrapper {
        padding-top: 29px;
        padding-bottom: 29px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .stroke-text {
        font-size: 50px;
        line-height: 101%;
        opacity: 0.6;
    }
    .stroke-icon{
        width: 20%;
    }
    .solution-info-header {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}