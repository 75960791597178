@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.features-accordion {
    padding-bottom: 9.375rem;

    h2 {
        margin-bottom: 6.625rem;

    }

    .accordion-item {

        border-bottom: 0.031rem solid $primaryBtnColor;
        padding: 2rem;
        position: relative;

    }
    .accordion {
        --bs-accordion-bg: transparent;

        .accordian-collapse {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          will-change: height;

          &.collapsing {
            position: relative;
            height: 0;
            overflow: hidden;
            -webkit-transition: height 0.1s ease;
            transition: height 0.1s ease;
          }
        }
        .accordion-button::after {
          background-size: 12px;
          background-position: center;
        }
        
        .item-gradient {
            .accordion-button::after {
                transform: rotate(180deg);
            }
        }
      }
    .w-87 {
        width: 87%;
    }

    .accordion-button,
    .features-accordion .accordion-button:not(.collapsed) {
        padding-bottom: 0 !important;
    }

    .item-border-right {
        border-right: 0 !important;

    }

    .border-top-solid {
        width: 100%;
        border-top: 9px solid $primaryGreenColor;
        border-radius: 4px 4px 0px 0px;
        position: absolute;
        top: -1px;
        left: 0;
    }


    .item-gradient {
        border: 0.031rem solid;
        border-image-source: linear-gradient(to left top, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));

    }

    .accordian-header {
        background-color: $bodybackgroundColor;
    }

    .accordion-button:focus {
        box-shadow: none;
        border-color: rgba(0, 0, 0, .125);
    }

    .accordion-button {
        span {
            width: 70%;
            line-height: 150%;
        }
    }

    .accordion-button,
    .accordion-button:not(.collapsed) {
        color: var(--bs-accordion-active-color);
        background-color: $bodybackgroundColor;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
        background: transparent;
        filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));
        font-size: 1.75rem;
        padding: 0;
        padding-bottom: 1.563rem;
        --bs-accordion-border-color: transparent;
        --bs-accordian-border: 0;

    }

    .accordion-button:not(.collapsed) {
        .accordian-heading-green-part {
            color: $primaryGreenColor;
        }
    }

    .accordion-item:first-of-type .accordion-button {
        border: none;
    }


    .accordion {
        border: none;
    }

    .accordion-body {
        padding: 0;
        font-size: 1rem;
    }

    .accordion {
        --bs-accordion-border-color: transparent;
        --bs-accordian-border: 0;
    }

    .accordion-button::after {
        background-image: url("../../../public/solutions/datacenter-colocation-assets/icon.svg");
        transform: rotate(0deg);
    }

    .accordion-button::not(.collapsed)::after {
        content: "";
        background-image: url("../../../public/solutions/datacenter-colocation-assets/active-icon.svg");
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }


    @media (min-width: $minSmallDevice) {
        .accordion-image-small {
            display: none;

        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        h2 {
            margin-bottom: 50px !important;
        }

        padding-bottom: 97px;

        .accordion-image {
            padding-left: 1rem;

            img {
                width: 100%;
            }
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .accordion-image {
            display: none;
        }


        h2 {
            margin-bottom: 51px !important;
        }

        .accordion-item {
            padding-top: 26px;
            padding-bottom: 26px;
        }

        .accordion-button {
            padding-bottom: 1rem !important;
        }
        .accordion-button::after {
            background-size: 14px;
        }


    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        h2 {
            padding: 10px;
        }

        .accordion-image {
            display: none;
        }

        .accordion-image-small {
            margin-top: 2rem !important;

        }

        h2 {
            margin-bottom: 50px !important;
            text-align: center;
        }

        .accordion-item {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .accordion-button {
            padding-bottom: 0 !important;
        }
        .accordion-button::after {
            background-size: 12px;
        }
        padding-bottom: 66px;

    }
}