@import "../../sassStyles/colors";
@import "../../sassStyles/variables";
@import "../../sassStyles/fonts";

.sensus-wrapper {
    .hero-content {
        height: 100vh;
    }

    .product-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .product-header {
        padding-bottom: 28.938rem;
        padding-top: 4.813rem;

        h2 {
            width: 80%;
            margin: auto;
        }
    }

    .sensus-diagram-heading {
        padding-top: 10.563rem;
        padding-bottom: 6.438rem;
    }

    .sensus-intelligence-section {
        background: linear-gradient(121.91deg, #000000 -35.25%, rgba(25, 46, 54, 0) 102.22%);
        filter: $bgFilter;
        border-radius: 0.25rem;
        margin-top: 7.938rem;
        margin-bottom: 12.875rem;
        border: 0.125rem solid;
        border-image-source: linear-gradient(to left, rgb(73, 200, 73), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        padding-top: 4.8125rem;
        text-align: center;

        h2 {
            max-width: 36.56rem;
        }
    }

    .sensus-intelligence-img {
        margin-top: 4.8125rem;
    }

    .sensus-accessories-section {
        margin-top: 11.063rem;
    }

    .passive-patch-panel-section {
        margin-bottom: 12.5rem;
    }

    .accessories-container {
        margin-top: 11.063rem;
        margin-bottom: 7.5rem;

        .rack-row {
            background: $bgGradientProduct;
            filter: $bgFilter;
            border: 0.06rem solid;
            border-image-source: linear-gradient(to top, rgb(73, 200, 73), rgba(73, 200, 73, 0));
            border-image-slice: 1;
            padding-top: 4.644rem;
            padding-bottom: 1.563rem;
            padding-left: 1.25rem;
            margin-right: 0.938rem;
        }

        .mounting-brackets-row {
            background: $bgGradientProduct;
            filter: $bgFilter;
            border: 0.15rem solid;
            border-image-source: linear-gradient(to bottom, rgb(73, 200, 73), rgba(73, 200, 73, 0));
            border-image-slice: 1;
            padding-top: 2.875rem;
            padding-bottom: 3.265rem;
            padding-left: 1.25rem;
            margin-left: 0.938rem;
            height: 100%;
        }

        .compact-cover-row,
        .rear-cover-row {
            background: $bgGradientProduct;
            filter: $bgFilter;
            border: 0.06rem solid;
            border-image-source: linear-gradient(to top, rgb(73, 200, 73), rgba(73, 200, 73, 0));
            border-image-slice: 1;
            padding: 2.5rem;
            height: 100%;
        }
    }

    .sensus-diagram-text {
        font-size: 1.25rem;
        line-height: 140%;
    }

    .sensus-diagram-cta {
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }

    .product-info-header {
        padding-top: 8.125rem;
        padding-bottom: 10.313rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
    }

}

@media (min-width: $maxExtraLargeDevice) {
    .product-video {
        object-fit: contain !important;
    }
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .sensus-wrapper {
        .product-info-header {
            h2 {
                div.more-products-text {
                    font-size: 7.5rem;
                }
            }
        }

        .passive-patch-panel-section {
            .text-gradient {
                font-size: 2.375rem;
            }
        }
    }
}

@media (min-width: $midSmallDevice) and (max-width: $minMediumDevice) {
    .sensus-wrapper {
        .hero-content {
            height: auto;
            margin-top: 80px;
        }
        .sensus-diagram-heading {
            padding-bottom: 0rem;
        }

        .sensus-intelligence-section {
            margin-bottom: 9.375rem;
        }

        .passive-patch-panel-section {
            margin-bottom: 11.75rem;

            .text-gradient {
                font-size: 2rem;
            }
        }

        .sensus-accessories-section {
            margin-top: 10.187rem;
        }

        .accessories-container {
            margin-top: 5.75rem;
            margin-bottom: 5.4rem;
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 8.867rem;

            h2 {
                div.more-products-text {
                    font-size: 7.06rem;
                }
            }
        }
    }
}

@media (max-width: $midSmallDevice) and (min-width: $maxTabDevice) {
    .sensus-wrapper {
        .hero-content {
            height: auto;
            margin-top: 80px;
        }
        .accessories-container {
            .rack-row {
                margin-right: 0;
            }

            .mounting-brackets-row {
                margin-left: 0;
            }
        }

        .accessories-container {
            margin-top: 5.75rem;
            margin-bottom: 5.4rem;
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 8.867rem;

            h2 {
                div.more-products-text {
                    font-size: 5.875rem;
                }
            }
        }
    }
}

@media (max-width: $maxMobileDevice) {
    .sensus-wrapper {
        .sensus-diagram-heading {
            padding-top: 7rem;
            padding-bottom: 0;
        }

        .sensus-intelligence-section {
            margin-bottom: 6rem;
        }

        .passive-patch-panel-section {
            margin-bottom: 4.5rem;
        }

        .sensus-accessories-section {
            margin-top: 4.375rem;

            .accessories-container {
                margin-top: 3rem;
                margin-bottom: 0;

                .rack-row {
                    margin-right: 0;
                }

                .mounting-brackets-row {
                    margin-left: 0;
                }
            }
        }

        .product-info-header {
            padding-top: 5.4rem;
            padding-bottom: 4rem;

            h2 {
                span {
                    font-size: 1.187rem;
                }

                div.more-products-text {
                    font-size: 3.187rem;
                }
            }
        }

        .container.sensus-challange-section,
        .hero-content {
            padding-left: 0px;
            padding-right: 0px;
        }
        .sensus-svg {
            max-height: 160px;
            margin-top: 20px;
        }
    }
}