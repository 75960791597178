@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.universal-breakout-wrapper {
    .hero-content {
        h1 {
            div {
                margin-top: -1rem;
            }
        }

        h5 {
            width: 45%;
            margin: auto;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        .hero-bg {
            background: url("../../../public/solutions/universal-breakout-assets/hero-bg.svg") no-repeat;
            background-position: center;
            background-size: contain;
            margin-top: 3.125rem;
            padding-top: 10.187rem;
            padding-bottom: 14.687rem;
        }
    }

    .solution-features {
        padding-top: 8.375rem;
        padding-bottom: 8.375rem;
        padding-left: 80px;
        padding-right: 80px;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;


        h5 {
            width: 100%;
            margin-top: 2.563rem;
            margin-bottom: 5.375rem;
            text-align: left;
        }

        .feature-margin-1 {
            margin-top: 10rem;
            margin-right: 20px;
        }

        .feature-margin-4 {
            margin-top: -10rem;
        }

        .feature-margin-3 {
            margin-right: 20px;

        }

        .feature-margin-2,
        .feature-margin-4 {
            margin-left: 20px;
        }

        .solution-features-elements {
            height: 16.875rem;

            padding: 2.125rem;
            padding-right: 1rem;
            border: 0.125rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }
    }

    .network-visibility-wrapper {
        padding-top: 10rem;
        padding-bottom: 8.562rem;

        .network-bg {
            background: url("../../../public/solutions/universal-breakout-assets/port-service-simplified.png") no-repeat;
            background-size: cover;
            text-align: center;
            padding-top: 8rem;
            padding-bottom: 8rem;

            h5 {
                width: 81.625rem;
                margin: auto;
                margin-top: 2.312rem;

                div {
                    margin-top: 1rem;
                }
            }
        }
    }

    .solution-info-header {
        padding-top: 9.375rem;
        padding-bottom: 9.375rem;
    }

    // .video-wrapper {
    //     padding-top: 8.5rem;
    //     padding-bottom: 8.5rem;
    // }

    .visibility-wrapper h2 {
        width: 70%;

    }

    @media (min-width: $midSmallDevice) {
        .universal-features {
            .features-accordion-container {
                min-height: 510px;
            }
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .network-visibility-wrapper {
            .network-bg {
                h5 {
                    width: 65%;
                }
            }
        }

        .hero-content h5 {
            width: 55%;
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 2.125rem;
            padding-left: 50px;
            padding-right: 50px;

            .solution-features-elements {
                padding: 1.5rem;
                height: 12rem;

                .icon {
                    height: 30px;
                    width: 30px;

                }
            }

            .container {
                padding-left: 75px;
                padding-right: 75px;
            }

            h5 {
                width: 87%;
            }
        }

        .divider-height {
            height: 1px;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .network-visibility-wrapper {
            padding-top: 6rem;
            padding-bottom: 6rem;

            .network-bg {
                padding-top: 5rem;
                padding-bottom: 5rem;

                h5 {
                    width: 75%;
                }
            }
        }

        .hero-content h5 {
            width: 65%;
        }

        .solution-features {
            padding-top: 100px;
            padding-bottom: 70px;

            .container {
                padding-left: 70px;
                padding-right: 70px;
            }

            h5 {
                width: 90%;
            }

            .solution-features-elements {
                height: 200px;
                padding: 1.5rem;

                .icon {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content h5 {
            width: 60%;
        }

        .hero-content {
            height: auto;

            h5 {
                width: 60%;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        .solution-features {
            padding-top: 60px;
            padding-bottom: 20px;
            padding-left: 40px;
            padding-right: 40px;

            h5 {
                margin-top: 40px;
                margin-bottom: 40px;
                width: 95%;
            }

            .container {
                padding-left: 40px !important;
                padding-right: 40px !important;
            }

            .solution-features-elements {
                height: 160px;
                padding: 1rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 1.75rem;
                    width: 1.75rem;
                }
            }
        }

        .network-visibility-wrapper {
            padding-top: 120px;
            padding-bottom: 120px;

            .network-bg {
                padding-top: 80px;
                padding-bottom: 80px;

                h5 {
                    width: 82%;
                    margin-top: 14px;
                }
            }
        }

        .solution-info-header {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .divider-height{
            height: 1px;
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .network-visibility-wrapper {
            padding-top: 70px;
            padding-bottom: 70px;

            .network-bg {
                padding-top: 70px;
                padding-bottom: 70px;

                h5 {
                    width: 90%;
                    margin-top: 14px;
                }
            }
        }

        .hero-content {
            padding-top: 3rem;
            height: 70vh;

            h5 {
                width: 80%;
            }
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 20px;
            padding-left: 30px;
            padding-right: 30px;

            h5 {
                margin-top: 2rem;
                margin-bottom: 3rem;
            }

            .container {
                padding-left: 72px !important;
                padding-right: 72px !important;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-content .hero-bg {
            background-size: cover;
            overflow: hidden;
        }

        .network-visibility-wrapper {

            padding-top: 50px;
            padding-bottom: 50px;

            .network-bg {
                background: none;
                padding-top: 0;
                padding-bottom: 0;

                h2 {
                    padding-top: 0;
                }

                h5 {
                    width: 85%;
                    margin: auto;

                    div {
                        margin-top: 1rem;
                    }
                }
            }
        }

        .hero-content h5 {
            width: 90%;
        }

        .hero-content h1 div {
            margin-top: 0;
        }


        .solution-features {
            padding-top: 50px;
            padding-bottom: 20px;
            padding-left: 30px;
            padding-right: 30px;

            h5 {
                width: 95%;
                text-align: center;
                margin-top: 37px;
                margin-bottom: 50px;
            }

            .container {
                padding-left: 30px !important;
                padding-right: 30px !important;
            }

            .visibility-wrapper {
                text-align: center;
            }

            .feature-margin-4,
            .feature-margin-2,
            .feature-margin-3,
            .feature-margin-1 {
                margin-left: 0;
                margin-right: 0;
            }

            .feature-margin-4 {
                margin-top: 0;
            }

            .feature-margin-1 {
                margin-top: 0;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }

        .solution-features-container {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-features {
            border-radius: 0;
        }

        .visibility-wrapper h2 {
            width: 100%;
        }

        .solution-diagram {
            content: url("../../../public/solutions/universal-breakout-assets/how-it-works-mobile.svg");
        }
    }
}