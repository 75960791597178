@import "../../sassStyles/global";
@import "../../sassStyles/colors";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
.all-products-wrapper .hero-content {
  height: 530px;
  text-align: center;
  flex-direction: column;
  background-image: url(../../../public/backgrounds/about-us-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 80px;
  .hero-heading {
    font-style: normal;
    font-weight: 700;
  }
  .hero-content-sub-heading {
    margin-top: 24px;
  }
  .hero-sub-heading {
    font-style: normal;
    // font-weight: 700;
    text-align: center;
    color: $secondaryWhiteColor;
   font-size: 68px;
   span{
    font-size: 68px;
   }
  }
}
.product-wrapper{
  margin-top: 120px;
  padding: 75px 70px 75px 70px;
  border-radius: 10px;
}

.product-btn {
  margin-top: 23px;
}

.slider-image {
  max-width: 100%;
}
.product-main-heading{
  font-size: 50px;
}
.product-description{
  font-size: 17px;
  font-weight: 400;
}
.product-features{
  font-size: 16px;
  font-weight: 700;
  gap: 40px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;

}
.feature-wrapper{
  border-radius: 6px;
  width: 218px;
  height: 218px;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.feature-liner{
  margin-top: 33px;
}
.product-image-container{
  display: flex;
  justify-content: center;
  flex-direction: column;

}
.product-logo{
  margin-bottom: 30px;
}
.last-product{
  margin-bottom: 120px;
}
.sensus-starling-description, .sensus-vision-description{
  margin-top: 100px;
}
.j-series-description{
  margin-top: 92px;
}
.cables-description{
  margin-top: 72px;
}
.opx-description{
  margin-top: 32px;
}
.sensus-ml-auto{
  margin-left: auto;
}

// APD Section Start
.apd-main-info-width {
  width: 96%;
  font-weight: 700;
  font-size: 22px;
}


.apd-slide-2-info-2 {
  width: 96%;
  margin-top: 100px;
}

.apd-slider-1 {
  width: 94%;
  z-index: 3;
  position: relative;
}
.j-series-image{
  width: 87%;
}

.apd-slide-2-info-1 {
  margin-top: 50px;
}

//APD Section End

//Sensus Section Start
.sensus-section {
  margin-top: 150px;
  background-color: $featuresbackgroundColor;
  padding-top: 150px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 10px;
}

.sensus-info-1 {
  margin-bottom: 50px;
}

.sensus-info-2 {
  margin-top: 50px;
}

.sensus-logo-margin {
  margin-bottom: 49px;
}

//Sensus Section End

//J-Series Section Start
.j-series-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.j-series-info-1 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.j-series-info-2 {
  margin-top: 75px;
}

//J-Series Section End

//OPX Section Start
.opx-section {
  background-color: $featuresbackgroundColor;
  padding-top: 150px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 10px;

}

.opx-info-1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.opx-info-2 {
  margin-top: 50px;
}

//OPX Section End

//Fiber Optic Cables Start
.fiber-optic-section {
  padding-bottom: 150px;
}

.fiber-optic-section {
  padding-top: 150px;
}

.fiber-optic-info-1 {
  margin-top: 150px;

}

//Fiber Optic Cables End

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {


  .opx-section,
  .sensus-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  .apd-slide-2-info-2 {
    width: 100%;
  }

  .sensus-info-1 {
    width: 86%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 80%;
  }

  .j-series-info-1 {
    width: 97%;
    margin-left: auto;
  }
  .product-main-heading{
    font-size: 41px;
  }
  .apd-main-info-width {
    font-size: 18px;
  }
  .product-wrapper{
    margin-top: 100px;
    padding: 66px 60px 66px 60px;
    border-radius: 10px;
  }
  .feature-wrapper{
    border-radius: 6px;
    width: 190px;
    height: 190px;
  }
  .feature-liner{
    margin-top: 20px;
  }
  .product-features{
    font-size: 13px;
    gap: 25px;
  }

}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
  .hero-content-sub-heading {
    width: 50%;
  }

  .opx-section,
  .sensus-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .apd-slide-2-info-1 {
    width: 90%;
    margin-left: auto;
  }

  .sensus-info-2 {
    width: 90%;
  }
}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  .hero-content .hero-content-sub-heading {
    width: 45%;
  }


  .opx-section,
  .sensus-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fiber-optic-section {
    padding-bottom: 100px;
  }

}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
  .apd-slider-2 {
    width: 100% !important;
  }

  .apd-slide-2-info-2 {
    width: 100%;
    margin-top: 60px;
  }

  .bottom-right-gradient {
    display: none;
  }

  .sensus-section,
  .opx-section {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sensus-info-1 {
    margin-top: 50px;
  }

  .j-series-section {
    padding-top: 50px;
    padding-bottom: 0;
  }

  .fiber-optic-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fiber-optic-info-1 {
    margin-top: 50px;
  }

  .opx-section,
  .sensus-section {
    padding-left: 0;
    padding-right: 0;
  }

  .j-series-info-1 {
    margin-bottom: 50px;
  }

  .j-series-info-2 {
    margin-top: 50px;
  }

  .product-btn {
    margin-right: 0.5rem !important;
  }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
  .hero-content {
    // height: 80vh;
    padding-left: 10px;
    padding-right: 10px;

    .top-left-bg {
      width: 60%;
    }

    .bottom-right-bg {
      width: 70%;
    }
  }

  .bottom-right-gradient,
  .top-left-gradient {
    display: none;
  }

  .hero-content .hero-content-sub-heading {
    width: 95%;
  }

  .product-btn {
    margin-right: 0 !important;

  }

  .opx-section,
  .sensus-section {
    padding-left: 0;
    padding-right: 0;
    margin-top: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .content-alignment-center {
    text-align: center;
  }

  .slider-image,
  .apd-slider-1,
  .apd-slider-2 {
    margin-top: 20px;
  }

  .apd-slide-2-info-2 {
    width: 92%;
    margin-top: 50px;
  }

  .sensus-section h3 {
    width: 80%;
    line-height: 110%;
    margin: auto;
  }

  .j-series-section,
  .fiber-optic-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .j-series-info-1 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .j-series-info-2,
  .fiber-optic-info-1 {
    margin-top: 50px;
  }

  .hero-content .top-left-bg {
    top: 7%;
  }

}