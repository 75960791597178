@import "../../sassStyles/colors";

.glc-support {
    background-color: $bodybackgroundColor;
    .w-50 {
        width: 50%;
    }

    .w-40 {
        width: 40%;
    }
    .w-45{
        width: 45%;
    }
    .w-30{
        width: 30%;
    }
    .w-90{
        width: 90%;
    }
    .support-heading {
        margin-bottom: 120px;
    }
    .support-hours {
        margin-top: 145px;
        margin-bottom: 145px;
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
        border-radius: 4px;
        .table-support-hours td,
        .table-support-hours th {
            padding-top: 26px;
            padding-left: 55px;
        }
        .incident {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
            color: #FFFFFF;
            opacity: 0.5;
        }
    }
    .warranty-type {
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
        border-radius: 4px;
        height: 288px;
        text-align: center;
    }
    .pricing-type {
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
        border-radius: 4px;
        height: 655px;
        padding-left: 25px;
        padding-right: 25px;
        text-align: center;
    }
    .pricing-title, pricing-info{
        font-size: 1rem;
    }
    .table-pricing tr{
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .table-border{
        border-radius: 4px;
    }
    .table-pricing td,
    .table-pricing th {
        padding-top: 25px;
        padding-left: 30px;
    }
    .table-warranty td,
    .table-warranty th {
        padding-top: 37px;
        padding-left: 52px;
    }
    .warranty-info {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
    }
    .types-container{
        margin-top: 145px;
        margin-bottom: 145px;
    }
    .contact-page {
        padding-top: 89px;
    }
    .contact-tile-container {
        padding-top: 9.5rem;
        padding-bottom: 9rem;
        margin-bottom: 89px;
        background: url("../../../public/backgrounds/contact-title-bg.png") no-repeat;
        height: 450px;
        width: 100%;
        background-size: contain;
        .contact-title {
            margin-bottom: 1rem;
            color: $colorWhite;
        }
        .contact-subtitle {
            margin: auto;
            width: 53%;
        }
    }
    .contact-form-container {
        border: 1px solid;
        border-image-source: linear-gradient(280.84deg, #48B84B -0.04%, rgba(72, 184, 75, 0) 69.47%);
        border-image-slice: 1;
        background: linear-gradient(4.48deg, #000000 5.37%, rgba(25, 46, 54, 0) 136.21%);
        // background-color: $backgroundBlack;
        position: relative;
        margin-bottom: 8.125rem;
        padding-bottom: 8.125rem;
        z-index: 3;
        max-width: 78rem;

        .form-title {
            margin-top: 100px;
        }

        .hubspot-form {
            margin-top: 4.25rem;
            margin-right: 120px;
            margin-left: 120px;
            color: $colorWhite;

            fieldset {
                width: 100%;
                max-width: 100% !important;
            }

            .hs-form-field input:-webkit-autofill,
            .hs-form-field input:-webkit-autofill:hover,
            .hs-form-field input:-webkit-autofill:focus,
            .hs-form-field textarea:-webkit-autofill,
            .hs-form-field textarea:-webkit-autofill:hover,
            .hs-form-field textarea:-webkit-autofill:focus,
            .hs-form-field input,
            .hs-form-field textarea {
                width: 100%;
                border: 1px solid #48B84B;
                filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
                border-radius: 4px;
                background-color: #181818;
                padding: 1rem 1.6rem;
                color: $colorWhite;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-background-clip: text;
                -webkit-text-fill-color: $colorWhite !important;
            }

            .hs-error-msg {
                color: red;
                margin-top: 10px;
            }

            .hs-form-field input::placeholder,
            .hs-form-field textarea::placeholder {
                color: $colorWhite;
            }

            .legal-consent-container {
                margin-top: 3.5rem;
            }

            .inputs-list {
                list-style: none;
                padding: 0;
            }

            .hs-form-booleancheckbox-display {
                width: 100%;
                display: flex;
                align-items: center;

                input {
                    accent-color: $securityBgColor !important;
                    width: 28px !important;
                    height: 28px;
                }
            }

            .hs_submit input {
                background: $securityBgColor;
                border-radius: 4px;
                color: $colorWhite;
                border-color: $securityBgColor;
                padding: 12px 60px;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
                line-height: 15px;
                margin-top: 20px;
            }
        }
    }

   .contact-form-container .nav-item .nav-link {
        padding-top: 55px;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: var(--bs-nav-tabs-link-active-color);
        background-color: transparent;
        border: 0;
        border-right: 1px solid $primaryGreenColor;
        border-bottom: 1px solid $primaryGreenColor;
    }

    .nav-tabs {
        border-bottom: 0;
        background: transparent;

    }

    .nav-tabs .nav-item {
        padding: 0;
        margin-right: 0
    }

    .circle-right {
        background: linear-gradient(180deg, rgba(78, 213, 77, 0.5) 32%, rgba(39, 139, 38, 0.5) 102.96%);
        filter: blur(200px);
        position: absolute;
        width: 400px;
        height: 400px;
        right: 0;
        top: 60%;
    }

    .circle-bottom {
        background: linear-gradient(180deg, rgba(78, 213, 77, 0.5) 32%, rgba(39, 139, 38, 0.5) 102.96%);
        filter: blur(200px);
        position: absolute;
        width: 200px;
        height: 200px;
        left: 50%;
        bottom: 0;
    }
    .faqs-accordion {
        padding-bottom: 9.375rem;
        padding-top: 9.375rem;

        h2 {
            margin-bottom: 6.625rem;

        }

        .accordion-item {

            border-bottom: 0.031rem solid $primaryBtnColor;
            padding: 3.688rem 2.75rem 2.75rem 2.75rem;
            position: relative;

        }

        .item-border-right {
            border-right: 0 !important;

        }
        .border-top-solid {
            width: 100%;
            border-top: 9px solid $primaryGreenColor;
            border-radius: 4px 4px 0px 0px;
            position: absolute;
            top:-1px;
            left:0;
        }


        .item-gradient {
            border: 0.031rem solid;
            border-image-source: linear-gradient(to left top, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;
            background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
            filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));

        }

        .accordian-header {
            background-color: $bodybackgroundColor;
        }

        .accordion-button:focus {
            box-shadow: none;
            border-color: rgba(0, 0, 0, .125);
        }

        .accordion-button {
            span {
                width: 70%;
                line-height: 150%;
            }
        }
        .accordion-body{
            width: 80%;
        }

        .accordion-button,
        .accordion-button:not(.collapsed) {
            color: var(--bs-accordion-active-color);
            background-color: $bodybackgroundColor;
            box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
            background: transparent;
            filter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));
            font-size: 1.75rem;
            padding: 0;
            padding-bottom: 1.563rem;
            --bs-accordion-border-color: transparent;
            --bs-accordian-border: 0;

        }

        .accordion-button:not(.collapsed) {
            .accordian-heading-green-part {
                color: $primaryGreenColor;
            }
        }

        .accordion-item:first-of-type .accordion-button {
            border: none;
        }


        .accordion {
            border: none;
        }

        .accordion-body {
            padding: 0;
            font-size: 1rem;
        }

        .accordion {
            --bs-accordion-border-color: transparent;
            --bs-accordian-border: 0;
        }

        .accordion-image {
            padding-left: 7.875rem;
        }

        .accordion-button::after {
            background-image: url("../../../public/solutions/datacenter-colocation-assets/icon.svg");
        }

        .accordion-button::not(.collapsed)::after {
            content: "";
            background-image: url("../../../public/solutions/datacenter-colocation-assets/active-icon.svg");
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transition: transform .2s ease-in-out;
        }
    }

    // @media (max-width: $breakpointLgTab) {
    //     .contact-form-container {
    //         margin-top: 6rem;

    //         .hubspot-form {
    //             margin-top: 3rem;
    //         }
    //     }
    // }

    // @media (max-width: $breakpointSmTab) {
    //     .contact-tile-container {
    //         padding-top: 4.5rem;
    //     }

    //     .contact-form-container {
    //         padding: 2.8rem;

    //         .hubspot-form {

    //             .hs-form-field:not(.hs-fieldtype-booleancheckbox) input,
    //             .hs-form-field textarea {
    //                 width: 100% !important;
    //             }
    //         }
    //     }

    //     .form-title {
    //         text-align: center;
    //     }
    // }

    // @media (max-width: $breakpointLgMobile) {
    //     .contact-form-container {
    //         margin-top: 3rem;
    //         .form-title {
    //             font-size: 16px;
    //             margin-top: 0;
    //         }
    //         .hubspot-form {
    //             margin-top: 25px;
    //         }
    //         .hs-form-field input:-webkit-autofill,
    //         .hs-form-field input:-webkit-autofill:hover,
    //         .hs-form-field input:-webkit-autofill:focus,
    //         .hs-form-field textarea:-webkit-autofill,
    //         .hs-form-field textarea:-webkit-autofill:hover,
    //         .hs-form-field textarea:-webkit-autofill:focus,
    //         .hs-form-field input,
    //         .hs-form-field textarea {
    //             padding: 14px 13px !important;
    //         }
    //     }
    //     .contact-tile-container {
    //         .contact-title {
    //             font-size: 42px;
    //         }

    //         .contact-subtitle {
    //             font-size: 12px;
    //         }
    //     }
    //     .footer p {
    //         font-size: 8px;
    //     }
    // }
}