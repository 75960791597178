@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.glc-resources-wrapper {
    // transform: translateZ(0);

    .hero-content {
        height: 100vh;
        max-height: 1080px;
        background: url("../../../public/company/resources/hero-bg.svg") no-repeat;
        background-size: cover;
        background-position: center;
    }

    .resource-hero-info {
        width: 45%;
        text-align: center;
        margin-top: 33px;
        margin-bottom: 49px;
        z-index: 1;
    }

    .hero-gradient {
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        position: absolute;
        left: 20%;
        margin: auto;
        top: 10%;
        width: 413px;
        height: 414px;
        filter: blur(12.5rem);
        z-index: 1;
    }

    .btn-doc-portal {
        padding-left: 62px;
        padding-right: 62px;
        z-index: 1;
    }

    .hero-left-pattern {
        background: url("../../../public/company/resources/hero-left-pattern.svg") no-repeat;
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
    }

    .hero-right-pattern {
        background: url("../../../public/company/resources/hero-right-pattern.svg") no-repeat;
        right: 0;
        height: 100%;
        position: absolute;
        left: 45%;
        top: 5%;
    }

    .resources-pills-wrapper {

        .nav-item {
            margin-left: 38px;
        }
    }
}

.featured-content-wrapper {
    margin-bottom: 180px;

    .heading-featured-content {
        margin-bottom: 119px;
    }

    .text-product {
        width: 82%;
        line-height: 150%;
        font-size: 28px;
        margin: auto;
        text-align: center;
        margin-top: 63px;
        margin-bottom: 63px;
    }
    .slick-slide {
        transform: translateZ(0);
    }

    .product-element {
        position: relative;
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0px 5.34929px 53.4929px rgba(0, 0, 0, 0.12));
        border-radius: 5.34929px;
        padding-top: 40px;
        height: 440px;
        border: 0.06rem solid;
        border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
        border-image-slice: 1;
    }

    .btn-read-more {
        position: absolute;
        bottom: 2rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 184px;
    }

    .text-element {
        width: 85%;
        line-height: 120%;
        margin: auto;
        margin-top: 22px;
        margin-bottom: 22px;
        font-size: 40px;
    }

    .product-text {
        width: 81%;
        margin: auto;
        margin-bottom: 28px;
    }

    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: $primaryGreenColor;
    }

    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 5rem;
        line-height: 1.25rem;
        position: absolute;
        top: 0.938rem;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        content: '-';
        text-align: center;
        opacity: .25;
        color: #FFFFFF;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.glc-resources {
    .resources-pills-wrapper {
        overflow-x: auto;

        .nav-pills {
            filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
            border-radius: 1000px;
            text-align: center;
            margin-bottom: 135px;
            margin-top: 80px;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            --pills-count: 5;
            --total-width: 100%;

            li {
                flex: 0 0 auto;
                margin-right: 10px;
            }

            .colorIndicator {
                position: absolute;
                width: calc(var(--total-width) / var(--pills-count));
                background-color: #000;
                transition: transform 0.3s ease;
                z-index: -1;
                border-radius: 100px;
            }

            .nav-link {
                color: $primaryGreenColor;
                background-color: transparent;
                border: none;
                outline: none;
                transition: color 0.3s ease;
                cursor: pointer;
                font-size: 28px;

                &.active {
                    color: #fff;
                }
            }

            .nav-item {
                flex: 0 0 auto;
                margin-right: 10px;
            }
        }
    }
}

.icon-max-height {
    max-height: 130px;
    max-width: 100%;
}

.resources-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;

    .resource-element {
        background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
        filter: drop-shadow(0px 5.34929px 53.4929px rgba(0, 0, 0, 0.12));
        border-radius: 5.34929px;
        padding-top: 60px;
        height: 580px;
        border: 0.06rem solid;
        border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        padding-left: 46px;
        padding-right: 20px;
        position: relative;
    }

    .resource-element-heading {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 90%;
    }

    .btn-download {
        position: absolute;
        bottom: 50px;
    }
}

.blog-container {
    margin-bottom: 150px;
}

.resources-wrapper .video-element {
    padding-top: 24px;
    padding-left: 22px;
    padding-right: 22px;
    height: 440px;
}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
    .nav-pills .nav-link.active {
        background: #000000;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
        border-radius: 1000px;
    }

    .nav-item {
        margin-right: 1rem;
    }

    .glc-resources .resources-pills-wrapper .nav-pills .nav-link {
        font-size: 23px;
        line-height: 27.6px;
    }

    .resources-wrapper .video-element {
        padding-top: 24px;
        padding-left: 22px;
        padding-right: 22px;
        height: 400px;
    }

    .featured-content-wrapper .product-element {
        height: 480px;
    }

    .glc-resources-wrapper .hero-right-pattern {
        top: 3%;
    }
}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
    .featured-content-wrapper {
        .text-element {
            font-size: 1.25rem;
            line-height: 1.875rem;
        }

        .product-element {
            height: 465px;
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        .nav-item {
            .nav-link {
                font-size: 1.25rem;
            }
        }
    }

    .resources-wrapper {
        padding-top: 6rem;

        .resource-element {
            padding: 40px 24px 45px 30px;

            &.datasheet {
                height: 620px;
            }

            &.ebook {
                height: 600px;
            }

        }
    }
}

@media (min-width: $midSmallDevice) and (max-width: $maxSmallDevice) {
    .featured-content-wrapper {
        .text-element {
            font-size: 1.125rem;
            line-height: 1.68rem;
        }

        .product-element {
            height: 435px;
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        .nav-item {
            .nav-link {
                font-size: 1.125rem;
            }
        }
    }

    .resources-wrapper {
        padding-top: 5.25rem;

        .resource-element {
            padding: 40px 24px 45px 30px;
            height: 510px;

            &.video-element {
                height: 450px;
            }
        }
    }

    .primary-btn {
        font-size: 0.75rem;
    }
}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {

    .featured-content-wrapper {
        .btn-read-more {
            max-width: 150px;
        }

        .text-element {
            font-size: 0.875rem;
            line-height: 1.68rem;
        }

        .product-element {
            height: 470px;

            .product-text {
                line-height: 1.125rem;
            }
        }
    }

    .glc-resources .resources-pills-wrapper .nav-pills {
        margin-top: 3.125rem;

        .nav-item {
            margin-left: 0.5rem;

            .nav-link {
                font-size: 1.125rem;
                padding: 8px 13px;
            }
        }
    }

    .resources-wrapper {
        padding-top: 3.125rem;

        .resource-element {
            padding: 40px 24px 45px 30px;
            height: 580px;

            &.video-element {
                height: 350px;
            }
        }
    }
}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .glc-resources-wrapper {
        .resource-hero-info {
            width: 100%;
        }

        .hero-content {
            background: url("../../../public/company/resources/hero-bg-mobile.svg") no-repeat;
            background-size: cover;
            background-position: center;

            h1 {
                z-index: 1;
            }
        }

        .nav {
            background-color: transparent;
        }

        .glc-resources .resources-pills-wrapper .nav-pills {
            overflow-x: auto;
            flex-direction: column;
            height: 45px;
            padding: 0;
            border-radius: 0;

            .nav-item {
                margin-left: 0.687rem;

                .nav-link {
                    font-size: 0.875rem;
                    background-color: #2A3D44;
                    border-radius: 100px;
                }
            }

        }
    }

    .featured-content-wrapper {
        .heading-featured-content {
            margin-bottom: 30px;
            z-index: 1;
            position: relative;
        }

        .product-element {
            height: auto;
            margin: 5px !important;
            padding-bottom: 34px;

            .btn-read-more {
                min-width: 0;
                width: auto;
                position: static;
            }

            .product-text {
                line-height: 21px;
            }

            .text-element {
                line-height: 24px;

                span {
                    font-size: 20px;
                }
            }
        }
    }

    .resources-wrapper {
        padding-top: 43px;

        .resource-element {
            padding: 28px 32px 33px 34px;
            height: auto;

            .primary-btn {
                min-width: 0;
            }

            .btn-download {
                position: relative;
                bottom: 0;
            }
        }
    }
}