.modal-body {
    img {
        max-width: 100%;
        height: auto;
    }
}
.modal-content {
    border-radius: 30px;
}

.modal-dialog, .modal-content {
    max-width: 1152px !important;
    max-height: 600px !important;
    // min-height: auto !important;
}