@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.automated-documentation-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    .hero-content {

        // margin-top: 150px;
        .hero-top-left-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 21.188rem;
            height: 21.188rem;
            filter: blur(12.5rem);
            z-index: 1;
            left: 20%;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        h1 {
            z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -1rem;

            div {
                margin-top: -1rem;
            }
        }

        h5 {
            width: 46%;
            position: relative;
            z-index: 3;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        .hero-heading-bg {
            /* background: url("../../../public/solutions/datacenter-colocation-assets/hero-bg.svg") no-repeat; */
            height: 15.125rem;
            width: 50rem;
            text-align: center;
            position: relative;
        }
    }

    .solution-features {
        padding-top: 8.375rem;
        padding-bottom: 8.375rem;
        padding-left: 100px;
        padding-right: 100px;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;

        .solution-features-elements {
            height: 19rem;
            padding: 2.125rem;
            border: 0.06rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }

        .visibility-wrapper {
            padding-left: 86px;
        }
    }

    .layer-intelligence-wrapper {
        padding-top: 181px;
        padding-bottom: 100px;

        h2 {
            width: 80%;
        }

        h5 {
            // font-size: 28px;
            width: 90%;
            margin-top: 48px;
        }
    }

    .physical-layer-intelligence-image {
        width: 100%;
    }

    @media (min-width: $midSmallDevice) {
        .automated-features {
            .features-accordion-container {
                min-height: 610px;
            }
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .hero-content {
            h5 {
                width: 60%;
            }

            #hero-bg-vector {
                width: 70% !important;
            }
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 2.125rem;
            padding-left: 47px;
            padding-right: 47px;
        }

        .layer-intelligence-wrapper {
            h5 {
                width: 100%;
                margin-top: 48px;
            }
        }

        .features-accordion h2 {
            margin-bottom: 5.563rem;
        }

        .visibility-wrapper {
            padding-right: 35px;

            h2 {
                padding-bottom: 50px;
            }
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .hero-content {
            #hero-bg-vector {
                width: 65% !important;
            }

            h5 {
                width: 68%;
            }
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 2.125rem;
            padding-left: 47px;
            padding-right: 47px;
        }

        .solution-features .visibility-wrapper {
            padding-left: 45px;

            h5 {
                margin-top: 40px;

            }

        }

        .solution-features .solution-features-elements {
            height: 205px;
            padding-left: 13px;
            padding-right: 8px;
            padding-top: 32px;
            padding-bottom: 20px;
        }

        .solution-features-elements .icon {
            height: 41px !important;
            width: 50px !important;
        }

        .physical-layer-intelligence-image {
            width: 80%;
        }

        .layer-intelligence-wrapper h5 {
            width: 100%;
            margin-top: 40px;

            .primary-btn {
                margin-top: 40px !important;
            }

        }

        .layer-intelligence-wrapper {
            padding-top: 140px;
            padding-bottom: 140px;
        }

        .features-accordion h2 {
            margin-bottom: 5.5rem;
        }

        .accordion-item {
            border-bottom: 0.031rem solid #48B74B;
            padding: 2.5rem 1.5rem 1.5rem 1.5rem;
        }

        .features-accordion {
            padding-bottom: 75px;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            height: 80vh;

            #hero-bg-vector {
                width: 50% !important;
            }

            h1 {
                bottom: 4rem;
            }

            h5 {
                width: 60%;
                margin-top: -2rem;
                margin-bottom: 2rem;
            }
        }

        .solution-features {
            padding-top: 4.5rem;
            padding-bottom: 1.5rem;
            padding-left: 2.25rem;
            padding-right: 2.25rem;
        }

        .solution-features .solution-features-elements .icon {
            height: 2rem;
            width: 2rem;
        }

        .solution-features .visibility-wrapper {
            padding-left: 23px;

            h2 {
                margin-bottom: 23px;

            }
        }

        .solution-features .solution-features-elements {
            height: 173px;
        }

        .solution-features .solution-features-elements {
            height: 205px;
            padding-left: 13px;
            padding-right: 8px;
            padding-top: 32px;
            padding-bottom: 20px;

            h4 {
                margin-top: 10px;
            }
        }

        .layer-intelligence-wrapper {
            padding-top: 100px;
            padding-bottom: 50px;

            h2 {
                width: 100%;
                margin-bottom: 23px;
            }

            h5 {
                width: 94%;
                margin-top: 23px;
            }

            .primary-btn {
                margin-top: 23px !important;
            }


        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .hero-content {
            #hero-bg-vector {
                width: 40% !important;
            }

            height: 74vh;

            h5 {
                width: 69%;
                margin-top: -4rem;
            }

            h1 {
                bottom: 6rem;
            }
        }

        .solution-features .solution-features-elements {
            height: 175px;
            padding-left: 13px;
            padding-right: 13px;
            padding-top: 26px;
            padding-bottom: 20px;
        }

        .solution-features .solution-features-elements h4 {
            margin-top: 15px;
        }

        .solution-features .solution-features-elements .icon {
            height: 2.25rem;
            width: 2.25rem;
        }

        .layer-intelligence-wrapper {
            padding-top: 75px;
            padding-bottom: 75px;

            h5 {
                margin-top: 20px;
                width: 100%;
            }

            .primary-btn {
                margin-top: 20px !important;
            }
        }

        .solution-features {
            padding: 4.5rem;
            padding-bottom: 1.5rem;


            .visibility-wrapper {
                padding-left: 0;
                text-align: center;

                h5 {

                    width: 90%;
                    margin: auto;
                    margin-bottom: 106px;
                    margin-top: 36px;

                }
            }
        }


    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-content {

            // height: 80vh;
            #hero-bg-vector {
                width: 30% !important;
            }

            .hero-heading-bg {
                height: 10rem;
            }

            h5 {
                width: 77%;
            }
        }

        .solution-feature-container {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-features {
            padding: 50px;

            .solution-features-elements {
                height: 165px;
                padding: 21px;

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

            .visibility-wrapper {
                padding-left: 0;
                text-align: center;

                h5 {
                    margin-bottom: 50px;
                    margin-top: 50px;

                }
            }

        }

        .layer-intelligence-wrapper {
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;

            h2 {
                width: 90%;
                margin: auto;
            }

            h5 {
                margin: auto;
                width: 85%;
                margin-bottom: 0;
                margin-top: 50px;
            }

            .physical-layer-intelligence-image {
                display: none;
            }


        }

        .solution-diagram {
            content: url("../../../public/solutions/automated-documentation-assets/how-it-works-mobile.svg");
        }

    }
}