@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

#cables-wrapper {
    .hero-content {
        height: 75vh;
        padding-top: 11.5rem;

        h1 {
            z-index: 1;
            position: relative;
        }

        .cables-and-clips-hero-image {
            position: relative;
            max-height: 400px;
            z-index: 1;
        }

        .cables-and-clips-hero-gradient {
            width: 54.438rem;
            height: 20.938rem;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            filter: blur(12.5rem);
            margin: auto;
            position: absolute;
        }
    }

    .cables-and-clips-hero-text {
        line-height: 120%;
        width: 80%;
        margin: auto;
    }

    .cables-and-clips-info-text {
        width: 78%;
        line-height: 126%;
    }

    .product-header {
        padding-bottom: 28.938rem;
        // padding-top: 4.813rem;
        // z-index: -1;
        background: transparent;

        h2 {
            width: 75%;
            margin: auto;
        }

        .cables-and-clips-header-bottom-gradient {
            width: 28.125rem;
            height: 28.125rem;
            right: 0;
            bottom: 0;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            filter: blur(12.5rem);
            margin: auto;
            position: absolute;
        }
    }

    .product-info {
        padding-top: 8rem;
        padding-bottom: 5.5rem;
        border: 0.125rem solid;
        border-image-source: linear-gradient(to left, rgb(73, 200, 73), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        width: 80%;
        // height: 40rem;
        margin-top: -21.313rem;
        margin-bottom: 16.875rem;
    }

    .density-explanation,
    .density-explanation span {
        font-style: normal;
        font-weight: 400;
        width: 93%;
    }

    .density-explanation span {
        font-weight: 700;
    }


    .cables-and-clips-connectivity-heading {
        padding-top: 133px;
        padding-bottom: 54px;
        width: 80%;
        margin: auto;
    }

    .connectivity-explanation,
    .connectivity-explanation span {
        padding-bottom: 7.375rem;
        text-align: center;
    }

    .connectors-info {
        padding-top: 22rem;
        padding-bottom: 8rem;
    }

    .section-background {
        background: url("../../../public/products/j-series-white-background.png") no-repeat;
        background-size: contain;
        background-position: center;
        height: 100%;
        width: 100%;
    }

    .product-info-header {
        padding-top: 9rem;
        padding-bottom: 9rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
    }
    .action-btn{
        display: inline-flex;
        vertical-align: middle;
        img{
            max-height: 18px;
        }

    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .cables-and-clips-connectivity-heading {
            width: 75%;
        }

        .product-header h2 {
            width: 74%;
        }

        .features-container {
            padding-bottom: 60px;
            margin-bottom: 0;

            .col-xl-3 {
                width: 33% !important;
            }

            .features-elements {
                min-height: 373px !important;
            }
        }

        .product-info-header {
            padding-top: 124px;

            h2 div {
                font-size: 133px;
            }
        }

        .product-info-header img {
            max-height: 318px;
        }

        .hero-content {
            height: 81vh;
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .connectors-info {
            padding-top: 12rem;
            padding-bottom: 5rem;
        }

        .product-info {
            padding-top: 75px;
            padding-bottom: 60px;
        }
        .product-info-header img {
            max-height: 260px;
        }

        .product-info-header {
            padding-top: 106px;
            padding-bottom: 106px;

            h2 div {
                font-size: 122px;
            }
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            height: 90vh;
        }

        .product-header h2 {
            width: 92%;
        }

        .product-header {
            padding-bottom: 25rem;
        }

        .product-info {
            text-align: center;
            padding-top: 40px;
            padding-bottom: 40px;

            img {
                max-height: 250px;
                width: auto !important;
                margin-top: 1rem;
            }
        }

        .product-info .col-lg-6,
        .product-info .col-lg-5 {
            width: 100%;
        }

        .density-explanation,
        .density-explanation span {
            width: 80%;
        }

        .connectors-info {
            padding-top: 15rem;
            padding-bottom: 4rem;
        }

        .cables-and-clips-connectivity-heading {
            padding-top: 76px;
            padding-bottom: 45px;
            width: 100%;
        }

        .connectivity-explanation,
        .connectivity-explanation span {
            padding-bottom: 78px;
            width: 80% !important;
        }
        .product-info-header {
            padding-top: 75px;
            padding-bottom: 85px;

            img {
                max-height: 200px;
            }

            h2 div {
                font-size: 104px;
            }
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .hero-content .cables-and-clips-hero-image {
            position: relative;
            max-height: 300px;
        }

        .product-header h2 {
            width: 100%;
        }

        .product-info {
            text-align: center;
            padding-top: 70px;
            padding-bottom: 40px;

            img {
                max-height: 260px;
                width: auto !important;
                margin-top: 1rem;
            }
        }

        .density-explanation,
        .density-explanation span {
            width: 80%;
            margin: auto;
        }

        .cables-and-clips-info-text {
            width: 100%;
        }

        .connectors-info {
            padding-top: 18rem;
            padding-bottom: 3rem;
        }

        .product-header {
            padding-bottom: 24rem;
        }

        .connectors-variety {
            width: 80%;
            margin: auto;
        }
        .cables-and-clips-connectivity-heading {
            padding-top: 64px;
            padding-bottom: 33px;
        }
        .connectivity-explanation, .connectivity-explanation span {
            padding-bottom: 50px;
            text-align: center;
            width: 83%;
            margin: auto;
        }
        .product-info-header {
            padding-top: 60px;
            padding-bottom: 70px;

            img {
                max-height: 200px;
            }

            h2 div {
                font-size: 86px;
            }
        }
    }
    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-content .cables-and-clips-hero-image {
            position: relative;
            max-height: 200px;
            margin-top: 30px;
        }
        .primary-btn{
            position: relative;
            z-index: 4;
        }
        .product-info {
            width: 100%;
        }


        .product-info {
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;

            .row {
                margin-left: 0;
                margin-right: 0;
            }

            img {
                width: 80%;
            }
        }
        .cables-and-clips-info-text {
            width: 95%;
        }
        .density-explanation{
            width: 85%;
            margin: auto;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .product-header{
            padding-bottom: 25rem;
            .container{
                padding-left: 0!important;
                padding-right: 0 !important;
            }
            h2 {
                width: 98%;
            }

        }
        .connectors-info {
            padding-top: 24rem;
            padding-bottom: 3rem;
        }
        .cables-and-clips-connectivity-heading {
            padding-top: 50px;
            padding-bottom: 16px;
            width: 90%;
        }
        .connectivity-explanation{
            width: 100%;
            padding-bottom: 50px;
        }
        .product-info-header h2 {
            padding-top: 20px;
        }

        .product-info-header h2 div {
            -webkit-text-stroke-width: 0.4px;
            font-size: 46px;
        }
        .features-container {
            margin-top: 0;
            margin-bottom: 0;
        }

        .product-info-header {
            padding-top: 50px;
            padding-bottom: 20px;

            img {
                width: 55%;
            }
        }


    }
}