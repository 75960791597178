@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.all-solutions-wrapper {
    .solution-image-container {
        text-align: right;
        padding-top: 0;
    }

    .hero-bg {
        background: url("../../../public/solutions/all-solutions/hero-bg.svg") no-repeat;
        background-position: center;
        background-size: contain;
    }

    .solutions-top-left-gradient {
        position: absolute;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        width: 25.875rem;
        height: 25.875rem;
        filter: blur(12.5rem);
        z-index: 1;
        left: 20%;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .hero-content h5 {
        width: 36%;
        margin-top: 30px;
    }

    h1 {
        z-index: 2;
        position: relative;
    }

    .solutions-container {
        padding-top: 7rem;
        padding-bottom: 9.375rem;
    }

    .solution-element {
        padding: 3.45rem;
        border-radius: 1.25rem;
        height: auto;
        margin-top: 30px;

        h2 {
            margin-bottom: 1.875rem;
        }
    }

    .physical-security-image {
        width: 100%;
    }

    .one-liner-solution-info {
        font-style: normal;
        font-weight: 400;
        line-height: 165%;
        margin: 0;
    }

    .all-solutions-btn {
        padding-right: 2.781rem;
        padding-left: 2.781rem;
        margin-top: 1.875rem;
        z-index: 3;
        position: relative;
    }

    .element-border {
        border: 0.063rem solid $bodybackgroundColor;
    }

    .colocation-heading {
        width: 90%;
    }

    .universal-breakout-heading {
        width: 70%;
    }

    .data-center-colocation-bg {
        background: url("../../../public/solutions/all-solutions/data-center-colocation.png") no-repeat;
        background-size: cover;
    }

    .universal-breakout-bg {
        background: url("../../../public/solutions/all-solutions/universal-breakout.png") no-repeat;
        right: 0;
        background-size: cover;
    }

    .element-height {
        height: 47.5rem;

        h2 {
            margin-bottom: 0;
        }
    }

    .image-width {
        width: 80%;
        margin-top: -2.188rem;
    }

    .diagnostics-width {
        margin-top: -2rem;
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .element-height {
            height: 35rem;
        }

        .image-width {
            width: 70%;
        }

        .solution-element {
            padding: 3rem;

            h5 {
                width: 89%;
            }
        }

        .diagnostics-width {
            width: 80%;
            margin-top: -3rem;
        }

        .hero-content h5 {
            width: 35%;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .hero-content{
            h5{
                margin-top: 13px;
            }
        }
        .element-height {
            height: 31rem;

            h5 {
                width: 90%;
            }
        }

        .image-width {
            width: 75%;
            margin-top: -3rem;
        }

        .solution-element {
            padding: 2rem;
            margin-top: 20px;
        }

        .diagnostics-width {
            width: 80%;
            margin-top: -3rem;
        }

        .solutions-container {
            padding-top: 5.625rem;
            padding-bottom: 5.625rem;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .element-height {
            height: 25rem;
        }

        .hero-content {
            height: 60vh;

            h5 {
                margin-top: 7px;
            }

        }
        .solution-element {
            margin-top: 15px;
        }
        .image-width {
            width: 75%;
        }

        .solution-element {
            padding: 2.5rem;
        }

        .diagnostics-width {
            width: 80%;
            margin-top: -3rem;
        }

        .solutions-container {
            padding-top: 4.6rem;
            padding-bottom: 4.6rem;
        }

        .all-solutions-btn {
            font-size: 10px;
            padding-top: 0.438rem;
            padding-bottom: 0.438rem;
            padding-left: 23px;
            padding-right: 23px;
            height: auto;
            line-height: 11px;
            z-index: 3;
            position: relative;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .element-height {
            height: 365px;

        }

        .hero-content {
            height: 60vh;

            h5 {
                margin-top: 5px;
            }

        }

        .image-width {
            width: 80%;
            margin-top: -2.25rem;
            z-index: 3;
            position: relative;
        }

        .solution-element {
            padding: 20px;
        }

        .diagnostics-width {
            width: 85%;
            margin-top: -35px;
        }

        .solutions-container {
            padding-top: 4.6rem;
            padding-bottom: 4.6rem;
        }

        .all-solutions-btn {
            font-size: 10px;
            padding-top: 0.438rem;
            padding-bottom: 0.438rem;
            padding-left: 23px;
            padding-right: 23px;
            height: auto;
            line-height: 11px;
            z-index: 3;
            position: relative;
            margin-top: 10px;

        }

        .physical-security-image {
            width: 70%;
            margin-top: -46px;
        }

        .physical-solution-info {
            width: 60%;
        }

        .solution-element h2 {
            margin-bottom: 0.5rem;
        }

        .capacity-text {
            width: 90%;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .solutions-container {
            padding-top: 0;
            padding-bottom: 30px;
        }
        .hero-bg {
            background-size: cover;
        }
        .hero-content{
            height: 55vh;
            h5{
                width: 70%;
            }
        }
        .solutions-top-left-gradient {
            width: 200px;
            height: 200px;
            filter: blur(106px);
            z-index: 1;
            right: 10%;
        }

        .solution-element {
            padding: 20px;
            padding-top: 40px;
            height: auto;
            min-height: 500px;
            margin-top: 30px;
            text-align: center;


            h5 {
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
        .diagnostics-width {
            margin-top: 0;
        }

        .physical-security-image {
            margin-top: 2rem;
            width: 100%;
        }

        .image-width {
            width: 50%;
            margin-top: 0;
        }

        .solution-element h2 {
            margin-bottom: 0;
        }

        .all-solutions-btn {
            margin-top: 0;
        }
        .colocation-heading {
            width: 100%;
        }



        .solution-image-container {
            text-align: center;
            padding-top: 0;
        }

        .universal-breakout-heading {
            width: 100%;
        }

        .diagnostics-width {
            width: 100%;
        }

        .capacity-width {
            margin-top: 40px;
        }

    }
}