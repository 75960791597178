@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.capacity-management-wrapper {
    .hero-content {
        h1 {
            div {
                margin-top: -1rem;
            }
        }

        h5 {
            width: 45%;
            margin: auto;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    .hero-bg {
        background: url("../../../public/solutions/capacity-management-assets/capacity-hero-bg.svg") no-repeat;
        background-position: center;
        background-size: contain;
    }

    .solution-features {
        padding-top: 8.375rem;
        padding-bottom: 8.375rem;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;

        h5 {
            width: 79%;
            margin-top: 2.563rem;
            margin-bottom: 5.375rem;
            text-align: center;
        }

        .solution-features-elements {
            height: 19rem;
            padding: 2.125rem;
            padding-right: 1rem;
            border: 0.06rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }
    }

    .network-visibility-wrapper {
        padding-top: 10rem;
        padding-bottom: 10rem;

        .network-bg {
            background: url("../../../public/solutions/capacity-management-assets/simplified-visibility.svg") no-repeat;
            background-size: cover;
            text-align: center;
            padding-top: 7.25rem;
            padding-bottom: 7.25rem;
            width: 100%;


            h2 {
                // padding-top: 15.625rem;
            }

            h5 {
                width: 72%;
                margin: auto;
                margin-top: 2rem;
                // margin-bottom: 12.125rem;
            }
        }
    }
    @media (min-width: $midSmallDevice) {
        .capacity-features {
            .features-accordion-container {
                min-height: 610px;
            }
        }
    }
    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .network-visibility-wrapper {
            .network-bg {
                h5 {
                    width: 65%;
                }
            }
        }

        .hero-content h5 {
            width: 60%;
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 5.125rem;

            .container {
                padding-left: 75px;
                padding-right: 75px;
            }

            h5 {
                width: 78%;
            }
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .hero-bg {
            padding-top: 2rem;
        }

        .network-visibility-wrapper {
            padding-top: 8rem;
            padding-bottom: 8rem;

            .network-bg {
                h5 {
                    width: 75%;
                }
            }
        }

        .hero-content {
            height: 70vh;

            h5 {
                width: 65%;
            }
        }

        .solution-features {
            padding-top: 100px;
            padding-bottom: 100px;

            .col-xl-3,
            .col-lg-3 {
                margin-bottom: 0 !important;
            }

            .container {
                padding-left: 70px;
                padding-right: 70px;
            }

            h5 {
                width: 75%;
            }

            .solution-features-elements {
                height: 240px;
                padding: 1.75rem;
            }
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-bg {
            padding-top: 1rem;
        }

        .hero-content {
            height: 65vh;

            h5 {
                width: 60%;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        .solution-features {
            padding-top: 60px;
            padding-bottom: 60px;

            h5 {
                margin-top: 40px;
                margin-bottom: 40px;
                width: 83%;
            }

            .container {
                padding-left: 40px !important;
                padding-right: 40px !important;
            }

            .solution-features-elements {
                height: 185px;
                padding: 1rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 1.75rem;
                    width: 1.75rem;
                }
            }
        }

        .network-visibility-wrapper {
            padding-top: 120px;
            padding-bottom: 120px;

            .network-bg {
                padding-top: 80px;
                padding-bottom: 80px;

                h5 {
                    width: 82%;
                    margin: auto;
                }
            }
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .network-visibility-wrapper {
            padding-top: 70px;
            padding-bottom: 70px;

            .network-bg {

                padding-top: 60px;
                padding-bottom: 60px;


                h5 {
                    width: 90%;
                    margin: auto;
                }
            }
        }

        .hero-content {
            padding-top: 3rem;
            height: 60vh;

            h5 {
                width: 65%;
            }

            h1 div {
                margin-top: 0;
            }
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            .container {
                padding-left: 72px !important;
                padding-right: 72px !important;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-bg {
            background-size: cover;
        }

        .network-visibility-wrapper {

            padding-top: 50px;
            padding-bottom: 50px;

            .network-bg {
                background: url("");
                padding-top: 0;
                padding-bottom: 0;

                h2 {
                    padding-top: 0;
                }

                h5 {
                    width: 83%;
                }
            }
        }

        .hero-content {

            // height: 85vh;
            h5 {
                width: 90%;
            }

        }

        .hero-content h1 div {
            margin-top: 0;
        }

        .solution-features-container {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-features {
            border-radius: 0;
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            h5 {
                width: 90%;
            }

            .container {
                padding-left: 50px !important;
                padding-right: 50px !important;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

        }

        .solution-diagram {
            content: url("../../../public/solutions/capacity-management-assets/how-it-works-mobile.svg");
        }
    }
}