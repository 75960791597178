// Text and Background Colors
$primaryGreenColor: #4CB64B;
$primaryRedColor :  #BC0C29;
$secondaryPinkColor:#F5F0F4;
$bgPurpleColor: #270722;
$primaryDarkBlueColor: #1A2D36;
$primarySeaGreenColor: #148E7F;
$secondaryPurpleColor: #5A5DA8;
$secondaryYellowColor: #E8C354;
$secondaryWhiteColor: #F5F0F4;
$bodybackgroundColor: #F5F0F4;
$demobackgroundColor: #0D2128;
$colorWhite: #FFFFFF;
$footerTextColor: #AAACAD;
$featuresbackgroundColor: #2A3D44;
$apdInfoColor: #A8A8A8;
$bgSupport: #192E36;
$secondaryBtnTextColor: #48B74B;
$formBgColor: #243D46;
$fontsecondaryColor: #BABABA;
$btnSecondaryBg :#270722;

// Buttons and Button States Colors
$primaryBtnColor: #48B74B;
$hoverBtnColor: #62D565;
$pressedBtnColor: #26A829;
$disabledBtnColor: #898787;
$transparentBtnColor: transparent;
$seeMoreLinkColor: #005293;

//Gradients
$bgGradient: linear-gradient(180deg, rgba(78, 213, 77, 0.5) 32%, rgba(39, 139, 38, 0.5) 102.96%);
$bgGradientProduct: linear-gradient(37.41deg, #000000 3.71%, rgba(25, 46, 54, 0) 102.28%);
$textGradient: linear-gradient(90deg, #48B74B 0%, #118D80 69.78%);

//filters
$bgFilter: drop-shadow(0 0.25rem 2.5rem rgba(0, 0, 0, 0.12));

//Solutions Diagram Colors
$securityBgColor: #48B74B;
$capacityBgColor: #118D80;
$diagnosticsBgColor: #118D80;
$breakoutBgColor: #48B74B;
$documentationBgColor: #F5F0F4;

//Career Background Colors
$careerDemoColor: #1A2D36;
$careerContactColor: #118D80;

//Border Colors
$borderSecondayBtn: #48B74B;