@import "../../sassStyles/global";

.glc-experience-small-screen {
    padding-bottom: 300px;
    position: relative;

    .experience-info {
        width: 55%;
        margin: auto;
        padding-top: 35px;
        padding-bottom: 120px;
    }

    .padding-solution {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 80px;
    }

    .solution-icon {
        padding-bottom: 30px;
    }

    .solution-heading {
        align-items: center;
        border-radius: 11rem;
        font-size: 20px;
        line-height: 131.5%;
        padding-top: 7px;
        padding-bottom: 7px;
        max-width: 330px;
        margin: auto;
        margin-bottom: 30px;
    }

    .solution-info {
        width: 80%;
        margin: auto;

    }

    .glc-experience-bottom-dots {
        left: 0;
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .solution-icon {
            width: 76px;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $midSmallDevice) {
        .solution-icon {
            width: 76px;
        }

        .solution-heading {
            max-width: 320px;
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $maxTabDevice) {
        .experience-info {
            width: 60%;
            padding-bottom: 100px;

        }

        .solution-icon {
            width: 60px;
        }

        .solution-heading,
        .solution-info {
            font-size: 18px;
        }

        .solution-info {
            width: 100%;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        padding-bottom: 200px;
        .glc-experience-bottom-dots {
            bottom: -9rem;
        }
        .experience-info {
            width: 100%;
            padding-bottom: 50px;
        }

        .solution-icon {
            width: 50px;
        }

        .solution-info {
            width: 90%;
        }

        .padding-solution {
            padding-bottom: 50px;
        }

        .padding-solution {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-heading {
            width: 90%;
            max-width: 90%;
        }
    }
}