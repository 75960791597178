@import "../../sassStyles/variables";

.blog-container {
    padding: 4.5rem;
    border-radius: 1.875rem;
    max-width: 74.875rem;
}

@media (min-width: $minMediumDevice) and (max-width: $maxLargeDevice) {
    .blog-container {
        max-width: 907px;
        padding: 44px;
        margin-bottom: 120px !important;


    }

    .blog-button {
        width: 60px;
    }
}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
    .blog-container {
        max-width: 830px;
        padding: 40px;
        margin-bottom: 100px;
    }

    .blog-button {
        width: 55px;
    }

}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
    .blog-container {
        max-width: 633px;
        padding: 30px;
        margin-bottom: 80px !important;
    }

    .blog-button {
        width: 42px;
    }

}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .blog-container {
        padding: 30px;
        border-radius: 9px;
        width: 85%;
        margin: auto;
        margin-bottom: 50px !important;
    }

    .blog-button {
        width: 25px;
    }

    .blog-info {
        display: none;
    }

    .blog-title {
        font-size: 20px;
    }

    .blog-info-container {
        margin-block-start: auto;
    }

}