@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.layer-one-diagnostics-wrapper {
    .hero-content {
        h1 {
            div {
                margin-top: -1rem;
            }
        }

        h5 {
            max-width: 54.375rem;
            margin: auto;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
        }
    }

    .hero-bg {

        background: url("../../../public/solutions/capacity-management-assets/capacity-hero-bg.svg") no-repeat;
        background-position: center;
        background-size: contain;
    }

    .solution-features {
        padding-top: 8.375rem;
        background-color: $featuresbackgroundColor;
        border-radius: 1.25rem;

        h5 {
            max-width: 53.5rem;
            margin-top: 2.563rem;
            margin-bottom: 5.375rem;
            text-align: center;
        }

        .solution-features-elements {
            height: 16.875rem;
            padding: 2.125rem;
            padding-right: 1rem;
            border: 0.125rem solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;

            h4 {
                margin-top: 1.375rem;
            }

            .icon {
                height: 3.188rem;
                width: 3.188rem;
            }
        }
    }

    .network-visibility-wrapper {
        padding-top: 9rem;
        padding-bottom: 9rem;

        .continuity-bg {
            background: url("../../../public/solutions/layer-one-diagnostics-assets/continuity-test.png") no-repeat;
            background-size: cover;
            text-align: center;
            padding-top: 8rem;
            padding-bottom: 8rem;

            h5 {
                max-width: 70rem;
                margin: auto;
                margin-top: 2.437rem;
            }
        }
    }

    .solution-info-header {
        padding-top: 9.375rem;
        padding-bottom: 9.375rem;
    }

    .video-wrapper {
        text-align: center;
    }

    .solution-diagram {
        width: auto !important;
    }

    @media (min-width: $midSmallDevice) {
        .layer-features {
            .features-accordion-container {
                min-height: 560px;
            }
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .network-visibility-wrapper {
            padding-top: 110px;
            padding-bottom: 110px;

            .continuity-bg {
                padding: 6rem;

                h5 {
                    width: 77%;
                    margin: auto;
                    margin-top: 25px;
                }
            }
        }

        .hero-content h5 {
            width: 58%;
        }

        .solution-features {
            padding-top: 5.125rem;
            padding-bottom: 5.125rem;

            .container {
                padding-left: 75px;
                padding-right: 75px;
            }

            .solution-features-elements {
                margin-bottom: 30px;
            }

            h5 {
                width: 70%;
            }
        }

        .solution-info-header {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        .divider-height{
            height: 1px;
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {

        .hero-content h5 {
            width: 65%;
        }

        .solution-features {
            padding-top: 100px;
            padding-bottom: 70px;

            .container {
                padding-left: 70px;
                padding-right: 70px;
            }

            .solution-features-elements {
                margin-bottom: 30px;
            }

            h5 {
                width: 72%;
            }

            .solution-features-elements {
                height: 240px;
                padding: 1.75rem;
            }
        }

        .solution-info-header {
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .network-visibility-wrapper {
            padding-top: 6rem;
            padding-bottom: 6rem;

            .continuity-bg {
                padding-top: 5rem;
                padding-bottom: 5rem;

                h5 {
                    width: 80%;
                    margin-top: 14px;
                }
            }
        }


    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content h5 {
            width: 60%;
        }

        .hero-content {
            height: 70vh;

            h5 {
                width: 60%;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        .solution-features {
            padding-top: 60px;
            padding-bottom: 60px;

            h5 {
                margin-top: 40px;
                margin-bottom: 40px;
                width: 70%;
            }

            .container {
                padding-left: 40px !important;
                padding-right: 40px !important;
            }

            .solution-features-elements {
                height: 160px;
                padding: 1rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 1.75rem;
                    width: 1.75rem;
                }
            }
        }

        .network-visibility-wrapper {
            padding-top: 120px;
            padding-bottom: 120px;

            .continuity-bg {
                padding-top: 80px;
                padding-bottom: 80px;

                h5 {
                    width: 70%;
                    margin: auto;
                }
            }
        }

        .solution-info-header {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .solution-diagram {
            width: 80% !important;
        }
        .divider-height{
            height: 1px;
        }



    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .network-visibility-wrapper {
            padding-top: 70px;
            padding-bottom: 70px;

            .continuity-bg {
                padding-top: 60px;
                padding-bottom: 60px;


                h5 {
                    width: 70%;
                    margin: auto;
                }
            }
        }

        .hero-content {
            padding-top: 3rem;
            height: 70vh;

            h5 {
                width: 80%;
            }
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            .container {
                padding-left: 72px !important;
                padding-right: 72px !important;
            }

            h5 {
                width: 80%;
                margin-top: 25px;
                margin-bottom: 25px;
            }

            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;

                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

            .bert-image {
                display: none;
            }

        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }

        .solution-diagram {
            width: 75% !important;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-bg {
            background-size: cover;
        }

        .network-visibility-wrapper {

            padding-top: 50px;
            padding-bottom: 50px;

            .continuity-bg {
                background: none;
                padding-top: 0;
                padding-bottom: 0;

                h2 {
                    padding-top: 0;

                }

                h5 {
                    width: 75%;
                }
            }
        }

        .hero-content h5 {
            width: 86%;
        }

        .hero-content h1 div {
            margin-top: 0;
        }

        .solution-features {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                width: 80%;
            }

            h5 {
                width: 85%;
                margin-bottom: 50px;
                margin-top: 40px;
            }

            .container {
                padding-left: 50px !important;
                padding-right: 50px !important;
            }


            .solution-features-elements {
                height: 162px;
                padding: 1.5rem;


                h4 {
                    margin-top: 1rem;
                }

                .icon {
                    height: 2rem;
                    width: 2rem;
                }
            }

            .bert-image {
                display: none;
            }

        }

        .solution-features-container {
            padding-left: 0;
            padding-right: 0;
        }

        .solution-features {
            border-radius: 0;
        }

        .solution-info-header {
            padding-top: 45px;
            padding-bottom: 45px;

        }

        .solution-diagram {
            content: url("../../../public/solutions/layer-one-diagnostics-assets/how-it-works-mobile.svg");
        }
    }
}