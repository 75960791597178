@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.events-wrapper {

    .label {
        margin-left: 0;
        line-height: 150%;

    }

    .hero {
        padding-top: 80px;

        .hero-content {
            background: url("../../../public/company/events/events-bg.png") no-repeat;
            background-position: center;
            background-size: cover;
            height: 530px;
        }

        .hero-heading {
            font-size: 68px;
        }

        .hero-subheading {
            font-size: 24px;
        }

    }

    .upcoming-events-heading {
        margin-top: 130px;
        margin-bottom: 130px;
        font-size: 60px;
        font-weight: 400;
    }

    .event-container {
        border-radius: 12px;
        margin-bottom: 200px;

        .content {
            position: absolute;
            top: 100px;
            right: 50%;
            text-align: left;
            padding-left: 70px;
            font-weight: 700;
            line-height: 26.04px;

            .booth-container {
                background-color: $secondaryWhiteColor;
                max-width: 146px;
                text-align: center;
                color: $primaryRedColor;
                border-radius: 4px;
                padding-top: 10px;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            .date,
            .location {
                font-size: 22px;
                margin-bottom: 10px;
            }

            .event-title {
                font-size: 60px;
                margin-bottom: 12px;
            }

            .event-description {
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
                max-width: 83%;
                margin-bottom: 34px;
            }

            .btn-flex-container {
                gap: 20px;
            }
        }
    }

    .events-slider .slick-dots {
        position: absolute;
        bottom: 100px !important;
    }

    .wrapped-up-events {
        margin-bottom: 130px;

        .wrapped-up-heading {
            margin-bottom: 130px;
            font-size: 60px;
            font-weight: 400;
        }

        .wrapped-up-container {
            gap: 30px;
            max-width: 100%;

            .content {
                border-bottom-right-radius: 12px;
                border-bottom-left-radius: 12px;
                padding: 0 20px 20px 20px;
                text-align: left !important;
                height: 330px;

                h4 {
                    height: 66px;
                    line-height: 33px;
                    font-weight: 600;
                }

                p {
                    margin-top: 30px;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                }
            }
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
       .wrapped-up-events {
            .wrapped-up-container {
                justify-content: space-between;
                .flex-element {
                    width: 440px;

                    img {
                        width: 440px;
                    }
                }

                .content {
                    p {
                        font-size: 18px;
                    }
                }
            }

        }
        .event-container {
            .content {
                top: 85px;
                padding-left: 60px;
                .booth-container {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                .date,
                .location {
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                .event-title {
                    font-size: 40px;
                    margin-bottom: 12px;
                }
                .event-description {
                    font-size: 16px;
                }

                .btn-flex-container {
                    gap: 20px;
                }
            }
        }
        .label {
            margin-bottom: 30px;
        }

        .glc-story {
            margin-bottom: 120px;

            .text-story-heading {
                width: 65%;
                margin-top: 50px;
                margin-bottom: 40px;
                text-align: center;
            }

            .about-info {
                width: 60%;
                margin: auto;
                text-align: center;
            }

        }

        .glc-vision {

            margin-bottom: 110px;

            h3 {
                font-size: 30px;
                width: 90%;

                span {
                    font-size: 30px;
                }
            }

            h5 {
                margin-top: 30px;
                margin-bottom: 30px;
                width: 100%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 95%;
                height: 100%;
            }
        }

        .glc-mission {

            h3 {
                font-size: 30px;
                width: 90%;
                line-height: 110%;

                span {
                    font-size: 30px;
                    line-height: 110%;
                }
            }

            h5 {
                margin-top: 30px;
                margin-bottom: 30px;
                width: 88%;
            }

            img {
                width: 95%;
                height: 100%;
            }

        }

        .glc-visibility {
            margin-top: 140px;
            margin-bottom: 140px;

            h3 {
                margin-bottom: 90px;
            }

            .icons-wrapper {
                margin-left: auto;
                margin-right: auto;

                .icon {
                    margin-bottom: 27px;
                    max-width: 115px;
                }
            }

        }

        .glc-leadership {
            margin-top: 150px;
            margin-bottom: 150px;

            .text-leadership-stroke {
                font-size: 113px;
                line-height: 170%;
            }

            .leader-element {
                text-align: center;

                h3 {
                    font-size: 30px;

                    span {
                        font-size: 30px;
                    }
                }

                img {
                    width: 90% !important;
                }
            }

            .brand-margin {
                margin-top: -7rem;
                margin-bottom: 45px;
            }

            .text-behind-brand {
                width: 60%;
                text-align: center;
                margin-bottom: 70px;

            }

            .leader-info {
                text-align: center;
            }
        }

        .glc-awards {
            padding: 45px;

            h2 {
                margin-bottom: 30px;
            }

            h4 {
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .slick-list {
                margin: 0 -5px;
            }

            .slick-slide>div {
                padding: 0 5px;
            }

            .slick-prev:before,
            .slick-next:before {
                background-size: 45px;
                height: 45px;
                width: 45px;
            }

            .slick-list {
                max-width: 1100px;
                margin: auto;
            }

            .slick-width {
                max-width: 1300px;
                margin: auto;
            }

            .award-element img {
                height: 40px;
            }
        }

        .glc-products-stroke {
            font-size: 127px;
            line-height: 230%;
        }

        .margin-label {
            margin-top: -7rem;
        }

        .text-product {
            width: 65%;
        }

        .products-container {
            padding-left: 70px;
            padding-right: 70px;
        }

        .glc-corporate {
            margin-top: 85px;
        }

        .text-element {
            width: 62%;
            line-height: 120%;
        }

        .glc-products-heading {
            width: 100%;
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .label {
            margin-bottom: 24px;
        }

        .glc-story {
            margin-bottom: 110px;

            .text-story-heading {
                width: 75%;
                margin-top: 35px;
                margin-bottom: 20px;
                text-align: center;
            }

            .about-info {
                width: 62%;
                margin: auto;
                text-align: center;
            }
        }

        .glc-vision {
            margin-bottom: 70px;

            h3 {
                width: 85%;
            }

            h5 {
                margin-top: 24px;
                margin-bottom: 24px;
                width: 98%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .glc-mission {
            // margin-bottom: 120px;

            h5 {
                margin-top: 24px;
                margin-bottom: 24px;
                width: 98%;
            }

            img {
                width: 100%;
                height: 100%;
            }

        }

        .glc-visibility {
            // padding: 45px;
            margin-top: 140px;
            margin-bottom: 140px;

            h3 {
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 72px;
            }

            .icons-wrapper {
                margin-left: auto;
                margin-right: auto;

                .icon {
                    margin-bottom: 19px;
                    max-width: 95px;
                }
            }
        }

        .glc-leadership {
            margin-top: 90px;
            margin-bottom: 140px;

            .text-leadership-stroke {
                font-size: 85px;
                line-height: 170%;
            }

            .leader-element {
                text-align: center;

                h3 {
                    font-size: 20px;

                    span {
                        font-size: 20px;
                    }
                }

                h5 {
                    font-size: 14px;
                }

                img {
                    width: 90% !important;
                }
            }

            .brand-margin {
                margin-top: -5rem;
                margin-bottom: 29px;
            }

            .text-behind-brand {
                width: 68%;
                text-align: center;
                margin-bottom: 50px;

            }

            .leader-info {
                text-align: center;
            }
        }

        .glc-awards {
            padding: 45px;
            padding-left: 20px;
            padding-right: 20px;

            h2 {
                margin-bottom: 25px;
            }

            h4 {
                font-size: 15px;
                margin-top: 35px;
                margin-bottom: 20px;
            }

            .slick-list {
                margin: 0 -5px;
            }

            .slick-next {
                right: 2%;
            }

            .slick-slide>div {
                padding: 0 5px;
            }

            .slick-prev:before,
            .slick-next:before {
                background-size: 40px;
                height: 40px;
                width: 40px;
            }

            .award-element img {
                height: 32px;
            }
        }

        .glc-products-stroke {
            font-size: 116px;
            line-height: 170%;
        }

        .glc-products-heading {
            margin-top: -6rem;
        }

        .products-container {
            padding-left: 70px;
            padding-right: 70px;
        }

        .text-element {
            width: 70%;
        }

        .product-text {
            width: 84%;
        }

        .product-element {
            padding-top: 20px;
            height: 364px;
        }

        .text-product {
            margin-top: 39px;
            margin-bottom: 39px;
        }

        .glc-corporate-citizenship {
            background-size: contain;

            h5 {
                width: 70%;
            }
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            height: 50vh;
        }

        .glc-story {
            margin-bottom: 110px;

            .text-story-heading {
                width: 75%;
                margin-top: 26px;
                margin-bottom: 26px;
                text-align: center;
            }

            .about-info {
                width: 66%;
                margin: auto;
                text-align: center;
            }

        }

        .glc-vision {
            margin-bottom: 90px;

            h3 {
                width: 85%;
            }

            h5 {
                margin-top: 24px;
                margin-bottom: 24px;
                width: 100%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .glc-mission {
            // margin-bottom: 90px;

            h5 {
                margin-top: 24px;
                margin-bottom: 24px;
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
            }

        }

        .glc-visibility {
            margin-top: 120px;
            margin-bottom: 120px;

            h3 {
                width: 80%;
                margin: auto;
                margin-bottom: 70px;
            }

            .icons-wrapper {
                margin-left: auto;
                margin-right: auto;

                .icon {
                    margin-bottom: 28px;
                    max-width: 90px;
                }
            }
        }

        .glc-products-stroke {
            font-size: 78px;

        }

        .glc-leadership {
            margin-top: 90px;
            margin-bottom: 120px;

            .text-leadership-stroke {
                font-size: 78px;
                line-height: 170%;
            }

            .leader-element {
                text-align: center;

                h3 {
                    font-size: 20px;

                    span {
                        font-size: 20px;
                    }
                }

                h5 {
                    font-size: 14px;
                }

                img {
                    width: 90% !important;
                }
            }

            .brand-margin {
                margin-top: -5rem;
                margin-bottom: 29px;
            }

            .text-behind-brand {
                width: 68%;
                text-align: center;
                margin-bottom: 50px;

            }

            .leader-info {
                text-align: center;
            }
        }

        .glc-awards {
            padding: 40px;
            padding-left: 20px;
            padding-right: 20px;

            h2 {
                margin-bottom: 25px;
            }

            h4 {
                font-size: 14px;
                margin-top: 35px;
                margin-bottom: 20px;
            }

            p {
                font-size: 8.5px;
                line-height: 150%;
            }

            .slick-list {
                margin: 0 -5px;
                width: 95%;
                margin: auto;
            }

            .slick-next {
                right: 2%;
            }

            .slick-slide>div {
                padding: 0 5px;
            }

            .slick-prev:before,
            .slick-next:before {
                background-size: 40px;
                height: 40px;
                width: 40px;
            }

            .award-element img {
                height: 30px;
            }
        }

        .glc-products-heading {
            margin-top: -6rem;
        }

        .glc-products-stroke {
            font-size: 95px;
        }

        .text-product {
            margin-top: 35px;
            margin-bottom: 35px;
        }

        .products-container {
            padding-left: 50px;
            padding-right: 50px;
        }

        .text-element {
            width: 85%;
            line-height: 120%;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .product-element {
            padding-top: 10px;
            height: 290px;

            img {
                width: 45%;
            }
        }

        .product-text {
            width: 90%;
        }

        .btn-read-more {
            width: 125px;
            bottom: 2rem;
            font-size: 10px;
        }

        .glc-corporate-citizenship {
            margin-top: 0;
            margin-bottom: 0;
            background-size: contain;

            h5 {
                width: 70%;
            }
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .label {
            margin-bottom: 17px;
            max-width: 65px;
        }

        .hero-content {
            height: 50vh;
        }

        .glc-story {
            margin-bottom: 65px;

            .text-story-heading {
                width: 75%;
                margin-top: 15px;
                margin-bottom: 30px;
                text-align: center;
            }

            .about-info {
                width: 70%;
                margin: auto;
                text-align: center;
            }

        }

        .glc-vision {
            margin-bottom: 70px;

            h3 {
                width: 85%;
            }

            h5 {
                margin-top: 17px;
                margin-bottom: 17px;
                width: 100%;
            }

            .vision-image-wrapper {
                text-align: right;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .glc-mission {
            // margin-bottom: 70px;

            h5 {
                margin-top: 17px;
                margin-bottom: 17px;
                width: 100%;
            }

            img {
                width: 100%;
                height: 100%;
            }

        }

        .glc-visibility {
            margin-top: 100px;
            margin-bottom: 100px;

            h3 {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
            }

            .icons-wrapper {
                margin-left: auto;
                margin-right: auto;

                .icon {
                    margin-bottom: 17px;
                    max-width: 65px;
                }
            }
        }

        .glc-leadership {
            margin-top: 50px;
            margin-bottom: 90px;

            .text-leadership-stroke {
                font-size: 58px;
                line-height: 170%;
            }

            .leader-element {
                text-align: center;

                h3 {
                    font-size: 20px;

                    span {
                        font-size: 20px;
                    }
                }

                h5 {
                    font-size: 12px;
                }

                img {
                    width: 90% !important;
                }
            }

            .brand-margin {
                margin-top: -4rem;
                margin-bottom: 17px;
            }

            .text-behind-brand {
                width: 70%;
                text-align: center;
                margin-bottom: 40px;

            }

            .leader-info {
                text-align: center;
            }
        }

        .glc-awards {
            padding: 30px;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 100px;

            h2 {
                margin-bottom: 25px;
            }

            h4 {
                font-size: 10px;
                margin-top: 11px;
                margin-bottom: 13px;
            }

            p {
                font-size: 6px;
                line-height: 150%;
            }

            .slick-list {
                margin: 0 -5px;
                width: 95%;
                margin: auto;
            }

            .slick-next {
                right: 2%;
            }

            .slick-slide>div {
                padding: 0 5px;
            }

            .slick-prev:before,
            .slick-next:before {
                background-size: 30px;
                height: 30px;
                width: 30px;
            }

            .award-element img {
                height: 22px;
            }
        }

        .glc-products-stroke {
            font-size: 70px;
        }

        .glc-products-heading {
            width: 52%;
            margin-top: -4rem;
        }

        .products-container {
            padding-left: 12px;
            padding-right: 12px;
        }

        .btn-read-more {
            width: 125px;
            bottom: 2rem;
            font-size: 10px;
            padding-top: 7px;
            padding-bottom: 7px;
            ;
            // height: 27px;
        }

        .product-element {
            padding-top: 10px;
            height: 290px;

            img {
                width: 45%;
            }
        }

        .text-element {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .text-product {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .margin-label {
            margin-top: -4.5rem;
        }

        .product-text {
            line-height: 150%;
        }

        .glc-corporate-citizenship {
            margin-top: 60px;
            margin-bottom: 60px;
            padding-top: 185px;
            padding-bottom: 67px;
            background-size: contain;

            h5 {
                width: 75%;
            }
        }
    }

    @media (max-width: $maxMobileDevice) {

        .label {
            margin-bottom: 17px;
            max-width: 65px;
        }

        .hero-heading-bg {
            margin-bottom: 15px;
        }

        .glc-story {
            margin-bottom: 65px;

            .text-story-heading {
                width: 90%;
                margin-top: 0;
                margin-bottom: 50px;
                text-align: center;
            }

            .about-info {
                width: 92%;
                margin: auto;
                text-align: center;
            }

        }

        .glc-vision {
            margin-bottom: 70px;

            h3 {
                width: 100%;
            }

            h5 {
                margin-top: 17px;
                margin-bottom: 17px;
                width: 92%;
                font-size: 14px;
            }

            .vision-image-wrapper {
                text-align: right;
                margin-top: 50px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .glc-mission {
            // margin-bottom: 50px;

            .row {
                flex-flow: column-reverse;
            }


            h5 {
                margin-top: 17px;
                margin-bottom: 17px;
                width: 92%;
                font-size: 14px;
            }

            .mission-image-wrapper {
                margin-top: 50px;
            }

            img {

                width: 100%;
                height: 100%;
            }

        }

        .glc-visibility {
            // padding-top: 40px;
            padding-left: 17px;
            padding-right: 17px;
            margin-top: 50px;
            margin-bottom: 50px;
            // padding-bottom: 47px;

            h3 {
                width: 90%;
                margin: auto;
                margin-bottom: 50px;
            }

            h4 {
                font-size: 12px;
            }

            .col-sm-4 {
                width: 33%;
            }

            .icons-wrapper {
                margin-left: auto;
                margin-right: auto;

                .icon {
                    margin-bottom: 17px;
                    max-width: 65px;
                }
            }
        }

        .glc-leadership {
            margin-top: 50px;
            margin-bottom: 50px;

            .text-leadership-stroke {
                font-size: 40px;
                line-height: 88%;
            }

            .leader-element {
                text-align: center;
                margin-bottom: 50px;
            }

            .brand-margin {
                margin-top: -1rem;
                margin-bottom: 17px;
            }

            .text-behind-brand {
                width: 90%;
                text-align: center;
                margin-bottom: 50px;

            }

            .leader-info {
                text-align: center;
            }
        }

        .glc-awards {
            padding: 30px;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 50px;

            h2 {
                margin-bottom: 50px;
            }

            h4 {
                font-size: 20px;
                margin-top: 11px;
                margin-bottom: 13px;
            }

            p {
                font-size: 12px;
                line-height: 150%;
            }

            .award-element {
                margin-top: 75px;

                img {
                    max-width: 180px;
                    margin-bottom: 50px;
                }

            }
        }

        .glc-products-stroke {
            display: none;
        }

        .glc-products-heading {
            width: 95%;
            margin-top: 0;
        }

        .text-product {
            width: 95%;
            margin-top: 37px;
            margin-bottom: 37px;
        }

        .btn-read-more {
            margin-right: auto !important;
            margin-left: auto;
        }

        .products-container {
            padding-left: 0;
            padding-right: 0;
        }

        .product-element {
            padding-top: 10px;
            height: 310px;
            position: relative;
            margin-bottom: 2rem;
        }

        .product-text {
            width: 93%;
            line-height: 150%;
        }

        .text-element {
            margin-top: 20px;
        }

        .glc-products {
            padding-bottom: 0 !important;
        }

        .world-map-mobile {
            content: url("../../../public/company/contact-us/world-map-mobile.png");
        }

        .glc-corporate-citizenship {
            background: url("../../../public/company/about-us/corporate-citizenship-mobile.png") no-repeat;
            margin-top: 0;
            margin-bottom: 0;
            background-size: cover;

            h2 {
                margin-top: -4rem;
                width: 60%;

            }
        }
    }
}