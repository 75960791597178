@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.privacy-wrapper {
    margin-top: 200px;
    margin-bottom: 200px;
    position: relative;
    .external-link{
        color: $primaryGreenColor;

    }
    .external-link:hover{
        color: $hoverBtnColor;
    }

    h1 {
        margin-bottom: 80px;
        position: relative;
        z-index: 2;
    }

    h2 {
        margin-bottom: 130px;
        margin-top: 130px;
        position: relative;
        z-index: 2;
    }

    h5 {
        margin-bottom: 90px;
        position: relative;
        z-index: 2;
        width: 90%;
    }

    .privacy-policy-right-gradient {
        width: 700px;
        height: 700px;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        filter: blur(12.5rem);
        top: 0;
        right: 0;
        position: absolute;
    }

    .privacy-policy-left-gradient {
        width: 425px;
        height: 425px;
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        filter: blur(12.5rem);
        top: 20%;
        left: 0;
        position: absolute;
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        margin-top: 250px;
        margin-bottom: 150px;

        h2 {
            margin-bottom: 100px;
            margin-top: 100px;
        }

        h5 {
            margin-bottom: 60px;
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        margin-top: 150px;
        margin-bottom: 150px;

        h2 {
            margin-bottom: 100px;
            margin-top: 100px;
        }

        h5 {
            margin-bottom: 60px;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxMediumDevice) {
        margin-top: 150px;
        margin-bottom: 150px;

        h1 {
            margin-bottom: 60px;
        }

        h2 {
            margin-bottom: 80px;
            margin-top: 80px;
        }

        h5 {
            margin-bottom: 50px;
        }

        .privacy-policy-left-gradient {
            width: 200px;
            height: 200px;
        }

        .privacy-policy-right-gradient {
            width: 400px;
            height: 400px;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        margin-top: 150px;
        margin-bottom: 150px;
        .privacy-policy-left-gradient {
            width: 250px;
            height: 250px;
        }

        .privacy-policy-right-gradient {
            width: 400px;
            height: 400px;
        }

        h1 {
            margin-bottom: 50px;
        }

        h2 {
            margin-bottom: 60px;
            margin-top: 60px;
        }

        h5 {
            margin-bottom: 50px;
        }
    }
    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        margin-top: 150px;
        margin-bottom: 150px;
        .privacy-policy-right-gradient {
            width: 300px;
            height: 300px;
        }
        .privacy-policy-left-gradient {
           display: none;
        }
        h1 {
            margin-bottom: 50px;
        }

        h2 {
            margin-bottom: 50px;
            margin-top: 50px;
             text-align: center;
        }

        h5 {
            margin-bottom: 30px;
            width: 95%;
        }

    }
}