@import "../../sassStyles/global";
@import "../../sassStyles/variables";

.diagram-bg {
  background: url("../../../public/backgrounds/banner-bg.png") no-repeat;
  background-size: cover;
  min-height: 900px;


}

// *** GLC  ARCHITECTURE DIAGRAM SECTION ***
.glc-architecture-diagram {
  padding-top: 19rem;
  padding-bottom: 15rem;
  position: relative;

  .banner-heading {
    width: 100%;
    margin: auto;
    margin-bottom: 2.688rem;
    font-weight: 300;
    font-size: 4.25rem;
    line-height: 76px;
  }

  .architecture-gradient {
    width: 33.75rem;
    height: 33.75rem;
    background: $bgGradient;
    transform: translate3d(0, 0, 0);
    z-index: 1;
    filter: blur(12.5rem);
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 35%;
  }
}

// *** GLC  ARCHITECTURE DIAGRAM SECTION END ***

// *** GLC  EXPERIENCE SECTION ***
.glc-experience-small-screen {
  display: none;
}

.glc-experience {
  .solution-link {
    text-decoration: none;
  }

  .glc-experience-heading {
    font-size: 6.25rem;
    line-height: 6.25rem;
    position: absolute;
  }

  .bg-horizontal-dots {
    position: absolute;
    bottom: 24.9rem;
    right: 16.875rem;
  }

  .experience-gradient {
    width: 26.1875rem;
    height: 26.1875rem;
    background: $bgGradient;
    z-index: -1;
    filter: blur(12.5rem);
    transform: translate3d(0, 0, 0);
    margin: auto;
    position: absolute;
    left: 30%;
    top: 20%;
  }

  .glc-experience-bottom-dots {
    background: url("../../../public/backgrounds/experience-horizontal-dots.png") no-repeat;
    bottom: -4.5rem;
    height: 18.75rem;
    left: 15.75rem;
    position: absolute;
    width: 31.25rem;
  }

  .glc-experience-bottom-logo {
    background: url("../../../public/backgrounds/experience-bottom-logo.png") no-repeat;
    height: 18.75rem;
    bottom: 0;
    right: 0;
    position: absolute;
    width: 31.25rem;
  }

  .glc-experience-info {
    width: 90%;
    line-height: 120%;
  }
}

.glc-solutions-diagram {
  text-align: center;
  margin-top: 10.938rem;
  padding-bottom: 20.938rem;
  position: relative;
  z-index: 2;

  .relative-circle {
    position: relative;
    z-index: 2;
  }

  p {
    text-align: left;
    padding-left: 0.75rem;
  }
}

.physical-layer-security {
  position: absolute;
  right: 20.313rem;
  top: 13.875rem;
  z-index: 3;
}

.capacity-management {
  position: absolute;
  right: 15.875rem;
  top: 28.313rem;
  z-index: 3;
}

.universal-breakout {
  position: absolute;
  left: 15.875rem;
  top: 28.313rem;
  z-index: 3;
}

.automated-documentation {
  position: absolute;
  right: 19.688rem;
  top: 42.5rem;
  z-index: 3;
}

.layer-diagnostics {
  position: absolute;
  left: 19.688rem;
  top: 42.5rem;
  z-index: 3;
}

.datacenter-colocation {
  position: absolute;
  left: 20.313rem;
  top: 13.875rem;
  z-index: 3;
}

.diagram-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 131.5%;
  margin-top: 0.5rem;
}

.heading-container {
  border-radius: 12.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.438rem;
  height: 2.5rem;
  justify-content: center;

  p {
    text-align: left !important;
    padding-left: 0.25rem;
  }
}

.heading-container:hover {
  cursor: default;
}

.heading-container:hover+.heading-info {
  opacity: 1;
  transform: translateY(0);
}

.heading-info {
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: transform .5s, opacity .5s, -webkit-transform .5s;
}

.solution-info-right {
  display: inline;
  position: absolute;
  padding-left: 1.25rem;
  padding-top: 3.75rem;
  width: 17.5rem;
}

.solution-info-left {
  display: inline;
  position: absolute;
  width: 17.5rem;
  padding-left: 1.25rem;
  padding-top: 3.75rem;
  right: 17.063rem;
}

// *** GLC EXPERIENCE SECTION END ***

// *** GLC DEMO REQUEST SECTION ***
.glc-demo {
  margin: auto;
  z-index: 4;
  border-radius: 0.625rem 0.625rem 0 0;
  width: 80rem;
  margin-top: -18rem;
  text-align: left;
  height: 20rem;
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: 0;

  .glc-demo-heading {
    // padding-top: 2rem;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 700;
  }


  .glc-demo-info {
    font-weight: 400;
    font-size: 18px;
    line-height: 131.5%;
    min-height: 70px;
    // width: 80%;
    margin: auto;
    margin-top: 1.688rem;
    margin-bottom: 2.438rem;
    color: $fontsecondaryColor;
  }

  .segmentation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .segmentation-bg {
    background: url("../../../public/backgrounds/segmentation-bg.png") no-repeat;
    background-size: cover;
    width: 550px;
    height: 380px;
    background-position: center;
    /* Centers the image within the box */
    box-shadow: 8.23px 8.23px 49.4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 21.482px;
    padding-left: 40px;
    padding-top: 20px;
    // margin-right: 76px;

  }

  .icon-size {
    width: 105px;
    height: 105px;
  }

  .flex-container {
    justify-content: space-around;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .flex-item {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  .fill {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

// *** GLC DEMO REQUEST SECTION END***

// *** GLC BENEFIT SECTION ***
.glc-benefit {
  align-items: center;
  position: relative;
  padding-top: 8rem;
  // padding-bottom: 8rem;
  z-index: 3;

  .bg-dots {
    position: absolute;
    right: 15rem;
    z-index: -1;
  }

  .bg-logo-outline {
    position: absolute;
    top: 23.81rem;
    right: -9rem;
    z-index: -1;
    height: 66%;
  }

  .bg-logo-solid {
    position: absolute;
    top: 39rem;
    right: 0;
    z-index: -2;
  }

  .glc-products {
    padding-top: 180px;
    // padding-bottom: 115px;
    // margin-top: 120px;
  }



  .glc-products-heading {
    width: 75%;

  }


  .text-product {
    width: 88%;
    line-height: 150%;
    // margin: auto;
    // text-align: center;
    margin-top: 20px;
    margin-bottom: 63px;
    z-index: 2;
    position: relative;
  }

  .product-element {

    border-radius: 5.34929px;
    padding-top: 40px;
    height: 440px;
    border: 0.06rem solid;
    border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
    border-image-slice: 1;
    z-index: 2;
    position: relative;
    background-color: $primaryDarkBlueColor;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .btn-read-more {
    /* position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 28px; */
    width: 184px;
  }

  .text-element {
    width: 85%;
    line-height: 120%;
    margin-left: auto;
    margin-right: auto;
    // margin: auto;
    // margin-top: 22px;
    // margin-bottom: 22px;
    // font-size: 40px;
  }

  .product-text {
    width: 81%;
    margin: auto;
    // margin-bottom: 28px;

  }

  .border-top {
    border-top: 1px solid;
    border-color: #000000;
  }

  .border-bottom {
    border-bottom: 1px solid #000000;
  }

  .border-right {
    border-left: 1px solid #000000;
  }
}

// *** GLC BENEFIT SECTION END ***

// *** GLC DEMO REQUEST SECTION ***
.glc-request-demo {
  padding-bottom: 11.5rem;
  position: relative;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 3.688rem;
  }

  .request-demo-wrapper {
    border-radius: 12px;
    padding: 70px;

    .glc-request-demo-subheading {
      font-weight: 400;
      font-style: normal;
      margin-bottom: 1.313rem;
    }

    .btn-flex-container {
      gap: 20px;
    }
  }
}

// *** GLC PRODUCTS SECTION START ***
.glc-products-home-container {
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;

  .glc-products-top-left-gradient {
    position: absolute;
    width: 528px;
    height: 528px;
    top: 0;
    left: 0;
    background: $bgGradient;
    transform: translate3d(0, 0, 0);
    z-index: 1;
    filter: blur(12.5rem);

  }

  .glc-products-bottom-right-gradient {
    position: absolute;
    width: 370px;
    height: 370px;
    bottom: 0;
    right: 0;
    background: $bgGradient;
    transform: translate3d(0, 0, 0);
    z-index: 1;
    filter: blur(12.5rem);
  }

  .benefit-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-bottom: 5rem;
    align-items: center;
    gap: 4.0625rem;
    z-index: 1;
    position: relative;
  }

  .benefit-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 0.3125rem;

    padding: 2.75rem 1.8125rem;
    flex: 1;
    height: 21.438rem;
    border: 1px solid;
    border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
    border-image-slice: 1;
    background: linear-gradient(120.45deg, #000000 2.51%, rgba(25, 46, 54, 0) 102.26%);
    filter: drop-shadow(0px 5.34929px 53.4929px rgba(0, 0, 0, 0.12));

    img {
      padding-bottom: 33px;
    }

    h4 {
      line-height: 160%;
    }
  }

  .glc-benefit-heading {
    margin-bottom: 150px;
    text-align: center;
  }

  .benefit-elements {
    p {
      font-size: 1.25rem;
      line-height: 140%;
      font-style: normal;
    }

    .benefit-info {
      font-size: 0.8750rem;
      font-weight: 400;
      font-style: italic;
    }
  }

  .image-margin {
    margin-top: -3rem;
    margin-bottom: 2rem;
  }

  .product-element {
    height: 480px;
    border-radius: 12px;
  }

  .product-text {
    width: 79%;
    margin: auto;
    margin-bottom: 15px;

  }


}

.partners-background {
  background: url("../../../public/backgrounds/partner-logos.svg") no-repeat;
  background-size: cover;
  min-height: 950px;

}

// *** GLC PRODUCTS SECTION END ***

// *** GLC SOLUTIONS SECTION START ***

.glc-solutions-home-container {
  padding-top: 120px;

  .fm-solutions-heading {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 92px;
  }

  .solutions-element {
    padding: 50px 65px 50px 65px;
    border-radius: 12px;
    position: relative;
    max-width: 710px;
    background-color: #f5f0f4ba !important;

    // opacity: 0.7;
    .text-element {
      font-weight: 600;
      font-size: 27px;
      margin-bottom: 25px;
    }

    .solutions-link {
      color: $seeMoreLinkColor !important;
      font-size: 18px;
      text-decoration: none;
      font-weight: 600;
    }

  }

  .home-solutions-bg {
    background: url("../../../public/backgrounds/home-solutions-bg.svg") no-repeat;
    background-size: contain;
  }

  .solutions-flex-container {
    gap: 32px;
  }

  .hr-solutions {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .solution-text {
    font-size: 17px;
  }

  .badge {
    position: absolute;
    top: -14px;
    font-size: 26px;
    font-weight: 600;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

// *** GLC SOLUTIONS SECTION END ***

// *** Expertise Section Start ***
.expertise-section {
  padding-top: 80px;
  padding-bottom: 80px;

  .expertise-subheading {
    font-size: 56px;
    width: 80%;
    line-height: 84px;
    margin-top: 40px;
    margin-bottom: 20px;

  }

  .expertise-heading,
  .expertise-heading span {
    font-size: 70px;
    line-height: 94px;
    margin-bottom: 50px;
  }

  .expertise-grid {
    display: flex;
    flex-direction: row;
    gap: 25px;

    .column {
      flex: 1 1 48%;
    }

    .column-one {
      background-color: $bgPurpleColor;
      border-radius: 11px;
      padding: 60px 15px 60px 15px;

      img {
        width: 100%;
      }
    }

    .grid-two-column-one {
      background-color: $primaryRedColor;
      border-radius: 11px;
      padding: 60px 25px 60px 30px;
      margin-bottom: 20px;

      h2 {
        margin-top: 40px;
        margin-bottom: 60px;
      }
    }

    .grid-two-column-two {
      background-color: $btnSecondaryBg;
      border-radius: 11px;
      padding: 60px 15px 60px 30px;

      h2 {
        margin-top: 40px;
        margin-bottom: 60px;
      }

    }

  }

}

// *** GLC RESOURCES SECTION START ***

.resource-container {
  padding-bottom: 180px;

  .fm-resource-heading {
    margin-bottom: 100px;
  }

  // .slider-container{
  //   margin-left: 150px;
  // }
  .slick-slide {
    width: 382px !important;
    margin-right: 16px;
    margin-left: 16px;
  }

  // .slider-container{
  //   margin-left: 150px;
  // }
  //   .slick-dots li button::before {
  //     content: '';
  //     width: 57px;  /* Width of the pill */
  //     height: 10px; /* Height of the pill */
  //     display: block;
  //     background-color: #333;
  //     border-radius: 5px; /* Half of the height for a pill shape */
  //     transition: all 0.3s ease;
  // }
  // .slick-dots {
  //   display: flex !important;
  //   justify-content: center;
  //   padding: 0;
  //   list-style: none;
  // }

  // .slick-dots li {
  //   margin: 0 10px; /* Adjust margin to create space between pills */
  // }
  // .slick-dots .slick-active button::before {
  //   content: '';
  //   width: 123px;  /* Width of the pill */
  // }
  .content {
    padding: 20px;
    text-align: left !important;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 213px;

    h4 {
      height: 50px;
    }

    p {
      margin-top: 20px;
    }

  }

  .slide-title {
    font-size: 23px;
    line-height: 24.5px;
    text-align: left;
  }

  .slick-dots {
    bottom: -73px;
  }

}

// *** GLC RESOURCES SECTION END ***


@media (min-width: $minMobileDevice) and (max-width: $maxMediumDevice) {
  .glc-experience-desktop {
    display: none;
  }

  .glc-experience-small-screen {
    display: block;
  }

}

@media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
  .glc-experience {
    .glc-experience-info {
      width: 82%;
    }

    .relative-circle {
      width: 790px;
      height: 790px;
    }

    .icon {
      width: 110px;
    }

    .line {
      width: 74px;
    }

    .solution-info-left {
      padding-left: 20px;
      padding-top: 37px;
      right: 200px;
    }

    .solution-info-right {
      padding-left: 20px;
      padding-top: 37px;
    }

    .datacenter-colocation {
      left: 25%;
      top: 13%;
    }

    .universal-breakout {
      left: 20%;
      top: 33%;
    }

    .layer-diagnostics {
      position: absolute;
      left: 24%;
      top: 51%;
    }

    .physical-layer-security {
      right: 25%;
      top: 13%;
    }

    .capacity-management {
      right: 20%;
      top: 33%;
    }

    .automated-documentation {
      right: 24%;
      top: 51%;
    }
  }

  .glc-solutions-diagram {
    margin-top: 120px;
    padding-bottom: 250px;
  }

  .glc-architecture-diagram {
    .architecture-heading {
      width: 82%;
      margin: auto;
      margin-bottom: 2.688rem;
    }

    .architecture-image {
      width: 70%;
    }
  }

  .glc-benefit {
    .bg-logo-outline {
      height: 63%;
    }

    .bg-dots {
      height: 23%;
    }

    .glc-products {
      padding-top: 137px;
      // padding-bottom: 100px;
    }

    .product-element {
      height: 400px;
    }

    .text-product {
      width: 84%;
      margin-top: 20px;
      margin-bottom: 80px;
    }

    // padding-bottom: 2rem;
    .glc-benefit-heading {
      margin-bottom: 115px;
      margin-top: 130px;
    }

    .benefit-elements {
      img {
        padding-bottom: 23px;
      }
    }

    .benefit-wrapper {
      gap: 45px;
    }
  }

  .glc-products-home-container {
    position: relative;
    padding-top: 140px;
    padding-bottom: 140px;

    .benefit-wrapper {
      gap: 45px;
    }

    .benefit-elements p {
      font-size: 14px;
      // height: 300px;
    }
  }

  // Latest Changes - 14-06-2024
  .banner-heading {
    font-size: 4rem;

    span {
      font-size: 4rem;
    }
  }

  .diagram-bg {
    min-height: 830px;
  }
  .glc-products-home-container .product-text {
    height: 96px;
  }
  .partners-background {
    min-height: 830px;
  }
  .expertise-section{
    .expertise-heading {
      font-size: 60px;
      margin-bottom: 60px;
    }
    .expertise-subheading{
      font-size: 46px !important;
      line-height: 72px !important;
      margin-top: 100px;
    }
    h5{
      font-size: 22.5px;
      line-height: 40px;

    }
    .expertise-grid .column-one, .expertise-grid .grid-two-column-one, .expertise-grid .grid-two-column-two {
      padding: 60px 25px 60px 25px !important;
    }
    .expertise-grid .grid-two-column-one h2 , .expertise-grid .grid-two-column-two h2{
      margin-top: 0 !important;
      margin-bottom: 60px !important;
    }

  }
  .solutions-element .badge {
    font-size: 22px !important;
  }
}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {

  // *** GLC  ARCHITECTURE DIAGRAM SECTION ***
  .glc-architecture-diagram {
    .architecture-heading {
      width: 85%;
      margin: auto;
      margin-bottom: 2.688rem;
    }

    .architecture-image {
      width: 70%;
    }
  }

  .glc-demo {
    width: 638px;
  }

  .glc-benefit {
    padding-left: 0;
    padding-right: 0;

    .bg-logo-outline {
      height: 55%;
    }

    .glc-products {
      padding-top: 127px;
      padding-bottom: 100px;
    }

    .text-product {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .product-element {
      padding-top: 30px;
      height: 350px;
    }

    .btn-read-more {
      margin-bottom: 0;
    }

    .bg-dots {
      left: 0;
      width: 6%;
    }

    .benefit-elements {
      flex: 40% 1;
      padding: 1.5rem 1rem;
      height: fit-content;
    }

    .glc-benefit-heading {
      text-align: center;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }

  .glc-products-home-container {
    padding-top: 80px;
    padding-bottom: 80px;

    .benefit-wrapper {
      padding-left: 150px;
      padding-right: 150px;
      gap: 48px;
    }

    .glc-benefit-heading {
      margin-bottom: 90px;
    }

    .benefit-elements {
      flex: 33%;
    }

  }


  .glc-experience-small-screen .solution-icon {
    padding-bottom: 30px;
  }

  .diagram-bg {
    background: url("../../../public/backgrounds/diagram-bg-1200.png") no-repeat;
    background-size: contain;

  }

}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
  .glc-architecture-diagram {
    .architecture-heading {
      width: 100%;
      margin: auto;
      margin-bottom: 2.688rem;
    }

    .architecture-image {
      width: 70%;
    }
  }

  .glc-benefit {
    .glc-products {
      padding-top: 82px;
      padding-bottom: 75px;
    }

    .text-product {
      margin-top: 33px;
      margin-bottom: 40px;
    }

    .product-element {
      height: 350px;
      padding-top: 25px;

      .text-element {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .btn-read-more {
        margin-bottom: 0;
        bottom: 1.5rem;
      }
    }

    .bg-logo-outline {
      height: 56%;
    }

    .bg-dots {
      left: 0;
      width: 6%;
    }




    .bg-logo-outline {
      width: 30%;
      height: 55%;
      top: 21rem;
    }
  }

  .glc-products-home-container {
    padding-top: 95px;
    padding-bottom: 95px;

    .benefit-elements {
      flex: 33%;
      height: 15rem;
      padding: 1.75rem 1.5rem;

      p {
        font-size: 14px;
        ;
      }

      img {
        padding-bottom: 20px;
        width: 22px;
      }

      // padding: 1.5rem 1rem;
      // height: fit-content;
    }

    .glc-benefit-heading {
      margin-bottom: 80px;
    }


  }

  .glc-request-demo {
    padding-top: 154px;
    padding-bottom: 97px;

  }

  .glc-demo {
    width: 600px;

  }

  .glc-experience .glc-experience-bottom-logo {
    right: -13rem;
  }

  .glc-demo .glc-demo-heading {
    padding-top: 71px;
  }

  .glc-demo-info {
    font-size: 15px;
    ;
    width: 66%;
  }

  .benefit-wrapper {
    padding-left: 100px;
    padding-right: 100px;

  }

  .diagram-bg {
    background: url("../../../public/backgrounds/diagram-bg-992.png") no-repeat;
    background-size: contain;
  }


}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
  .glc-experience .glc-experience-bottom-logo {
    right: -13rem;
  }

  .glc-architecture-diagram {
    .architecture-heading {
      width: 100%;
    }

    .architecture-image {
      width: 70%;
    }

    .section-description {
      font-size: 22px;
    }
  }

  .glc-experience .experience-info {
    width: 60%;
  }

  .glc-benefit {
    .glc-products {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .text-element {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .product-element {
      height: 390px;
      padding-top: 25px;

      .text-element {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .btn-read-more {
        margin-bottom: 0;
        bottom: 1.5rem;
        font-size: 12px;
        width: 134px;
      }
    }

    .bg-dots {
      left: 0;
      width: 6%;
    }

    .glc-benefit-heading {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .bg-logo-outline {
      // width: 50%;
      top: 20rem;
      height: 55%;
    }
  }

  .glc-request-demo {
    padding-top: 154px;
    padding-bottom: 97px;

  }

  .glc-demo {
    width: 500px;

    .glc-demo-info {
      font-weight: 400;
      font-size: 14px;
      ;
      line-height: 131.5%;
      width: 69%;
      margin: auto;
      margin-top: 1.688rem;
      margin-bottom: 2.438rem;
    }

    .glc-demo-heading {
      padding-top: 3rem;
    }

  }

  .glc-experience .glc-experience-bottom-logo {
    right: -13rem;
  }

  .glc-products-home-container {
    padding-top: 77px;
    padding-bottom: 77px;

    .glc-products-top-left-gradient {
      width: 250px;
      height: 250px;
    }

    .glc-products-bottom-right-gradient {
      width: 250px;
      height: 250px;
    }

    .glc-benefit-heading {
      margin-bottom: 66px;
    }

    .benefit-wrapper {
      gap: 45px;
      padding-left: 100px;
      padding-right: 100px;
    }

    .benefit-elements {
      flex: 33%;
      padding: 1rem 1rem;
      height: 200px;

      p {
        font-size: 12px;
      }

      .benefit-info {
        font-size: 12px;
      }
    }


    .benefit-elements img {
      padding-bottom: 9px;
      width: 20px;
    }

  }
}

@media (max-width: $maxMobileDevice) {
  .slick-dots li button:before {
    font-size: 3rem !important;
  }

  .glc-request-demo {
    padding-top: 154px;
    padding-bottom: 54px;
    position: relative;

    p {
      font-size: 14px;

    }

    .top-left-bg {
      position: absolute;
      top: 0;
      width: 55%;
    }

    .bottom-right-gradient,
    .bottom-right-bg {
      display: none;
    }
  }

  .glc-architecture-diagram {
    padding-top: 12rem;
    padding-bottom: 5rem;

    .architecture-heading {
      width: 100%;

    }

    .architecture-image {
      width: 95%;
    }

    .section-description {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;

    }

    .architecture-gradient {
      width: 200px;
      height: 200px;
      filter: blur(39px);
      top: 45%;
    }
  }

  .glc-experience {
    .glc-experience-bottom-logo {
      display: none;
    }

    h2 {
      width: 80%;
      margin: auto;
      z-index: 1;
      position: relative;
    }
  }

  .glc-demo {
    width: 82%;
    margin-top: -6rem;
    border-radius: 4px 4px 0 0;

    .glc-demo-info {
      font-weight: 400;
      font-size: 12px;
    }

    .glc-demo-heading {
      padding-top: 50px;
    }

  }

  .glc-benefit {
    padding-bottom: 0;

    .glc-products {
      padding-top: 95px;
      padding-bottom: 50px;

      .product-element {
        height: 370px;
        padding-top: 30px;
        margin-bottom: 32px;

        .product-text {
          width: 85%;
        }
      }

      .text-product {
        width: 94%;
        margin-top: 27px;
        margin-bottom: 50px;
      }

      .text-element {
        width: 85%;
        line-height: 120%;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 3px;
      }

      .btn-read-more {
        margin-bottom: 0;
      }
    }

    .bg-logo-outline,
    .bg-dots {
      display: none;
    }

    .glc-benefit-heading {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .benefit-wrapper {
      gap: 32px;
    }

    .benefit-elements {
      flex: 100%;
    }
  }

  .glc-products {
    padding-top: 50px;
    padding-bottom: 50px;

    .row {
      margin-left: 0;
      margin-right: 0;

    }
  }

  .text-product {
    width: 95%;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .glc-products-bottom-left-gradient,
  .glc-products-top-right-gradient {
    display: none;
  }

  .glc-products-home-container {
    padding-top: 50px;
    padding-bottom: 50px;

    .glc-benefit-heading {
      margin-bottom: 50px;
    }

    .benefit-wrapper {
      gap: 32px;
    }

    .benefit-elements {
      height: 288px;
      padding: 29px 21px;
      flex: 100%;

      img {
        padding-bottom: 15px;
        width: 30px;
      }
    }

    .glc-products-top-left-gradient,
    .glc-products-bottom-right-gradient {
      display: none;
    }
  }


}

// *** GLC DEMO REQUEST END ***