@import "../../sassStyles/variables";

.features-container {
    margin-top: 15.625rem;
    // padding-left: 3.125rem;
    // padding-right: 3.125rem;
    margin-bottom: 3.125rem;

    .features-main-heading {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .features-heading {
        margin-top: 2rem;
        margin-bottom: 2rem;
        // height: 4rem;
    }
}

.features-elements {
    border: 0.125rem solid;
    border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
    border-image-slice: 1;
    padding: 1.875rem;
    min-height: 28.5rem;
    margin-bottom: 2.813rem;

    .icon {
        width: 3.813rem;
        height: 3.813rem;
    }
}

@media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
    .features-container {
        padding-bottom: 60px;
        margin-bottom: 0;

        .col-xl-3 {
            width: 33% !important;
        }

        .features-elements {
            min-height: 433px !important;
        }
    }
}

@media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
    .features-container {
        padding-bottom: 3rem;
        padding-left: 120px;
        padding-right: 120px;

        .features-elements {
            min-height: 24rem;
        }
    }

    .features-main-heading {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
    }

}

@media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
    .features-container {
        padding-bottom: 3rem;

        .features-elements {
            min-height: 350px;

            .icon {
                width: 42px;
                height: 42px;
            }
        }

        .features-heading {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .features-main-heading {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
    }

}

@media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
    .features-container {
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 50px;

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        .features-main-heading {
            padding-top: 50px;
            padding-bottom: 50px;
            text-align: center;
        }

        .features-heading {
            margin-top: 1rem;
            margin-bottom: 1rem;
            height: 3rem;
        }
    }

    .features-elements {
        padding: 25px;
        min-height: 335px;
        height: 335px;
        margin-bottom: 2.813rem;

        .icon {
            width: 42px;
            height: 42px;
        }
    }
}