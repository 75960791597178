@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.j-series-wrapper {
    .hero-content {
        height: 100vh;
        margin-top: 120px;
    }

    .j-series-video {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .product-header {
        padding-bottom: 28.938rem;
        padding-top: 4.813rem;

        h2 {
            width: 80%;
            margin: auto;
        }
    }

    .product-info {
        padding-top: 9rem;
        padding-bottom: 100px;
        border: 0.125rem solid;
        border-image-source: linear-gradient(to left, rgb(73, 200, 73), rgba(73, 200, 73, 0));
        border-image-slice: 1;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        width: 80%;
        // height: 56.188rem;
        margin-top: -21.313rem;
        margin-bottom: 16.875rem;
    }

    .modular-explanation,
    .modular-explanation span {
        // font-size: 1.75rem;
        // line-height: 199%;
        font-style: normal;
        font-weight: 400;
        width: 95%;
    }

    .modular-explanation span {
        font-weight: 700;
    }

    .modular-explanation-2,
    .modular-explanation-2 span {
        // font-size: 1.75rem;
        line-height: 150%;
        font-style: normal;
        font-weight: 400;
    }

    .modular-explanation-2 span {
        font-weight: 700;
    }

    .j-series-info-section-2 {
        padding-top: 36rem;
        padding-bottom: 11.125rem;
    }

    .section-background {
        background: url("../../../public/products/j-series-white-background.svg") no-repeat;
        background-size: contain;
        background-position: 0% 90%;
        height: 100%;
        width: 100%;
    }

    .j-series-diagram-heading {
        padding-top: 10.563rem;
        padding-bottom: 6.438rem;
    }

    .diagram-info-section {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .diagram-text {
        font-size: 1.75rem;
        line-height: 122%;
    }

    .diagram-single-text {
        position: absolute;
        left: 15%;
        bottom: 56%;
    }

    .diagram-compact-text {
        position: absolute;
        right: 15%;
        bottom: 50%;
    }

    .diagram-green-text {
        font-size: 2.125rem;
        line-height: 122%;
    }

    .diagram-multimode-text {
        right: 25%;
        top: 50%;
        position: absolute;
    }

    .product-info-header {
        padding-top: 10rem;
        padding-bottom: 9rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .features-container {
        margin-top: 0;
    }

    .action-btn {
        display: inline-flex;
        vertical-align: middle;
        img {
            max-height: 19px;
        }
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .product-info {
            padding-top: 90px;
            padding-bottom: 90px;
        }

        .j-series-info-section-2 {
            padding-top: 25rem;
            padding-bottom: 8rem;
        }

        .features-container {
            padding-bottom: 60px;
            margin-bottom: 0;

            .col-xl-3 {
                width: 33% !important;
            }

            .features-elements {
                min-height: 373px !important;
            }
        }

        .features-container .features-heading {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            height: 3rem;
        }

        .product-info-header h2 div {
            font-size: 112.398px;
            line-height: 124%;
        }

        .product-info-header {
            padding-top: 9rem;
            padding-bottom: 7rem;
        }
    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .product-info-header h2 div {
            font-size: 93.1347px;
            line-height: 124%;
        }

        .product-info {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .j-series-info-section-2 {
            padding-top: 20rem;
            padding-bottom: 6rem;
        }

        .diagram-info-section {
            padding-top: 150px;
            padding-bottom: 110px;
        }

        .j-series-diagram-heading {
            padding-top: 150px;
            padding-bottom: 100px;
        }

        .product-info-header {
            padding-top: 8rem;
            padding-bottom: 6rem;
        }
    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            height: auto;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-top: 78px;
            object-fit: contain;
        }

        .product-info {
            padding-top: 75px;
            padding-bottom: 75px;
        }

        .j-series-info-section-2 {
            padding-top: 18rem;
            padding-bottom: 5rem;
        }

        .j-series-diagram-heading {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }

        .diagram-info-section {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .product-info-header img {
            width: 70%;
        }

        .product-info-header {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .product-info-header h2 div {
            font-size: 84px;
            line-height: 124%;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .hero-content {
            height: auto;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-top: 78px;
            object-fit: contain;
        }

        .product-header h2 {
            width: 90%;
        }

        .product-header {
            padding-bottom: 25rem;
        }

        .product-info-header {
            padding-top: 6rem;
            padding-bottom: 5rem;

            h2 {
                padding-top: 30px;
            }

            h2 div {
                font-size: 65px;
            }

            img {
                width: 50%;
            }
        }

        .features-container {
            margin-top: 0;
            margin-bottom: 0;
        }

        .product-info {
            text-align: center;
            padding-top: 60px;
            padding-bottom: 60px;

            img {
                width: 45%;
            }
        }

        .j-series-info-section-2 {
            padding-top: 10rem;
            padding-bottom: 4rem;
        }

        .modular-image {
            display: none;
        }

        .modular-explanation {
            width: 90%;
            margin: auto;
            text-align: center;
        }

        .modular-explanation-2 {
            width: 86%;
            margin: auto;
        }

        .j-series-diagram-heading {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .diagram-info-section {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-content {
            height: auto;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-top: 78px;
            object-fit: contain;
        }

        .product-header h2 {
            width: 100%;
        }

        .features-container {
            margin-top: 0;
            margin-bottom: 0;
        }

        .primary-btn {
            margin-right: 0 !important;
        }

        .product-header {
            padding-bottom: 25rem;
        }

        .product-info {
            width: 100%;
        }

        .product-info {
            padding-top: 50px;
            text-align: center;

            .row {
                margin-left: 0;
                margin-right: 0;
            }

            img {
                width: 80%;
            }
        }

        .modular-explanation,
        .j-series-wrapper .modular-explanation span {
            width: 90%;
            margin: auto;
        }

        .modular-image {
            display: none;
        }

        .j-series-info-section-2 {
            padding-top: 340px;
            padding-bottom: 60px;
        }

        .j-series-diagram-heading {
            padding-top: 90px;
            padding-bottom: 50px;
        }

        .diagram-info-section {
            padding-top: 50px;
            padding-bottom: 50px;

            img {
                padding-top: 30px;
            }
        }

        .modular-explanation,
        .j-series-wrapper .j-series-wrapper .modular-explanation span {
            text-align: center;
        }

        .product-info-header h2 {
            padding-top: 20px;
        }

        .product-info-header h2 div {
            -webkit-text-stroke-width: 0.4px;
            font-size: 36.5px;
        }

        .product-info-header {
            padding-top: 50px;
            padding-bottom: 20px;

            img {
                width: 80%;
            }
        }

    }
}