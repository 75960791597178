@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.glc-team-wrapper {
    .hero-content {
        padding-top: 240px;
        min-height: 100vh;
        height: auto;
        position: relative;

        .team-info {
            width: 50%;
            margin: auto;
            text-align: center;
            margin-bottom: 40px;
        }

        .hero-top-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 413px;
            height: 414px;
            filter: blur(12.5rem);
            z-index: 1;
            right: 25%;
            top: 5%;
        }

        .team-hero-bg {
            background: url("../../../public/company/glc-team/team-hero-bg.png") no-repeat;
            width: 100%;
            height: 100%;
            text-align: center;
        }

        .team-video {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 49%;
            border-radius: 16px;
        }

    }

    // GLC Core Values Start
    .glc-core-container {
        position: relative;
    }

    .glc-core-values {
        margin-top: 250px;
        margin-bottom: 80px;

        h3,
        p {
            position: relative;
            z-index: 1;
        }

        .right-mid-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 413px;
            height: 414px;
            filter: blur(12.5rem);
            z-index: 1;
            right: 0%;
            top: 20%;

        }

        .left-mid-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 413px;
            height: 414px;
            filter: blur(12.5rem);
            z-index: 1;
            left: 0;
            top: 0;

        }

        .value-info {
            width: 55%;
            margin: auto;
            text-align: center;
            margin-bottom: 90px;
        }

        .value-element {
            max-width: 400px;

            p {
                opacity: 0.7;
            }
        }

        .element-padding-top {
            padding-top: 195px;
        }

        .value-heading {
            margin-top: -4rem;
            z-index: 1;
        }

        .value-stroke {
            font-size: 114px;
            line-height: 100%;
            -webkit-text-stroke-width: 0.063rem;
            -webkit-text-stroke-color: $colorWhite;
            color: transparent;
            opacity: 0.3;
        }

    }

    // GLC Core Values End

    // GLC Perks Section Start
    .glc-perks {
        margin-top: 150px;
        // margin-bottom: 150px;
        position: relative;

        .mid-gradient {
            position: absolute;
            background: $bgGradient;
            transform: translate3d(0, 0, 0);
            width: 413px;
            height: 414px;
            filter: blur(12.5rem);
            z-index: 1;
            left: 0;
            right: 0;
            margin: auto;
            top: 20%;

        }

        .perk-info {
            // font-size: 28px;
            width: 55%;
            margin: auto;
            text-align: center;
            margin-bottom: 49px;
        }

        .perks-flex {
            position: relative;
            z-index: 2;
            gap: 26px;

            div {
                margin-bottom: 37px;
            }

            .perks-element {
                border: 1px solid $primaryGreenColor;
                filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
                border-radius: 4px;
                padding: 46px 30px 46px 30px;
            }

            .perk-container-gradient {
                background: linear-gradient(104.34deg, #000000 25.91%, rgba(25, 46, 54, 0) 116.69%);
                box-shadow: 0px 4px 44px 10px rgba(0, 0, 0, 0.09);
            }
        }
    }


    // GLC Perks Section End
    .glc-culture {
        padding-top: 110px;
        padding-bottom: 150px;

    }

    // GLC Team Section Start

    .glc-team {
        padding-top: 150px;
        padding-bottom: 150px;

        .glc-arrow {
            margin-top: -10%;
        }

        .team-info {
            width: 53%;
            margin-bottom: 35px;
            margin-top: 30px;

        }

    }

    .glc-hiring-process {
        .hiring-text {
            width: 56%;
            margin-top: 46px;
            margin-left: auto;
            margin-right: auto;
        }

        .hiring-image {
            margin-top: 100px;
            margin-bottom: 150px;
        }
    }

    .glc-jobs {
        .job-department {
            margin-top: 75px;
            margin-bottom: 75px;
        }

        .jobs-wrapper {
            margin-top: 75px;
            margin-bottom: 75px;

        }

        .open-job {
            background: linear-gradient(106.55deg, #23404A 13.9%, rgba(35, 64, 74, 0) 67.04%);
            filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
            border-radius: 4px;
            padding: 22px;
            padding-left: 35px;
            border: 0.3px solid;
            border-image-source: linear-gradient(to left, rgba(73, 200, 73, 1), rgba(73, 200, 73, 0));
            border-image-slice: 1;
            height: 129px;
        }

        .job-title {
            font-size: 22px;
        }
    }

    .teams-slider {
        padding-top: 120px;

        .slide-item {
            height: 900px;
            background: $bodybackgroundColor;
            border-radius: 12px;
            padding: 10px;
        }

        .slide-item-small {
            width: 312px;
            height: 522px;
            margin: auto;
            margin-top: 300px;

        }

        .slide-item-large {
            width: 400px;
            height: 700px;

            img {
                width: 98%;
            }
        }

        .team-tag {
            color: white;
            text-align: center;
            background: linear-gradient(104.34deg, #000000 25.91%, rgba(25, 46, 54, 0) 116.69%);
            filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 17px;
            padding-bottom: 17px;
            width: 100%;
            margin-top: 20px;
        }
    }

    .glc-corporate {
        margin-top: 80px;
    }

    .text-leadership-stroke {
        font-style: normal;
        font-size: 150px;
        line-height: 170%;
        -webkit-text-stroke-width: 0.063rem;
        -webkit-text-stroke-color: $colorWhite;
        color: transparent;
        opacity: 0.3;

    }

    .brand-margin {
        margin-top: -8rem;
    }

    .text-behind-brand {
        text-align: center;
        margin-bottom: 60px;
    }

    .corporate-philosophy-text {
        width: 72%;
        text-align: center;
        line-height: 150%;
        // font-size: 28px;
        // margin-bottom: 120px;
    }

    .corporate-stroke {

        -webkit-text-stroke-width: 0.063rem;
        -webkit-text-stroke-color: $primaryGreenColor;
        color: transparent;
        opacity: 0.5;
        font-weight: 400;
        font-size: 200px;
        line-height: 100%;
    }

    .margin-label {
        margin-top: -9rem;
    }

    .margin-label-philosophy {
        margin-top: -8rem;
    }

    .hiring-btn {
        margin-top: 110px;
        margin-right: 0 !important;
        min-width: 225px !important;
    }
    .hiring-btn-hero{
        min-width: 225px !important;
        margin-right: 0 !important;
        margin-bottom: 40px;
    }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .text-leadership-stroke {
            font-size: 111.605px;
        }

        .brand-margin {
            margin-top: -7rem;
            z-index: 1;
        }

        .hero-content {
            padding-top: 200px;

            .team-video {
                top: 53%;
                width: 239px;
            }

            .team-info {
                width: 55%;
            }
        }

        .glc-core-values {
            margin-top: 144px;

            .value-element {
                max-width: 292px;
            }
        }
    }

    .hiring-btn {
        margin-top: 80px;
    }

    @media (min-width: $midSmallDevice) and (max-width: $maxMediumDevice) {
        .text-leadership-stroke {
            font-size: 111.605px;
        }

        .brand-margin {
            margin-top: -7rem;
            z-index: 1;
        }

        .hero-content {
            padding-top: 180px;

            .team-video {
                top: 56%;
                width: 204px;
            }

            .team-info {
                width: 65%;
            }
        }

        .glc-core-values {
            margin-top: 144px;

            .value-element {
                max-width: 292px;
            }

            h3.text-leadership-stroke {
                font-size: 6.4rem;
                line-height: 175.74px;
            }
        }

        .glc-team {
            padding-top: 130px;
        }

        .hiring-btn {
            margin-top: 70px;
        }
    }

    @media (min-width: $midSmallDevice) and (max-width: $maxSmallDevice) {
        .text-leadership-stroke {
            font-size: 111.605px;
        }

        .brand-margin {
            margin-top: -7rem;
            z-index: 1;
        }

        .hero-content {
            padding-top: 167px;

            .team-video {
                top: 53%;
                width: 170px;
            }

            .team-info {
                width: 55%;
            }
        }

        .glc-core-values {
            margin-top: 144px;

            .value-info {
                width: 45%;
            }

            .value-element {
                max-width: 292px;
            }

            h3.text-leadership-stroke {
                font-size: 5.187rem;
            }
        }

        .glc-team {
            padding-top: 110px;

            .team-info {
                width: 54%;
            }
        }

        .glc-perks .perks-flex div {
            margin-bottom: 26px;
        }

        .glc-hiring-process {
            .hiring-text {
                width: 64%;
            }
        }

        .hiring-btn {
            margin-top: 60px;
        }
    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .text-leadership-stroke {
            font-size: 111.605px;
        }

        .brand-margin {
            margin-top: -7rem;
            z-index: 1;
        }

        .hero-content {
            padding-top: 167px;

            .team-video {
                top: 55%;
                width: 150px;
            }

            .team-info {
                width: 55%;
            }
        }

        .glc-core-values {
            margin-top: 100px;

            .value-info {
                width: 45%;
            }

            .value-element {
                max-width: 292px;
            }

            .glc-corporate {
                margin-top: 0;
            }

            h3.text-leadership-stroke {
                font-size: 3.812rem;
                line-height: 190px;
            }
        }

        .glc-team {
            padding-top: 110px;

            .team-info {
                width: 75%;
            }
        }

        .glc-perks {
            .perks-flex {
                div.d-flex {
                    margin-bottom: 12px;
                }

                div.perks-element {
                    margin-bottom: 26px;
                    padding: 21px 4px 13px 25px;

                    p {
                        line-height: 18px;
                    }
                }
            }
        }

        .glc-hiring-process {
            .hiring-text {
                width: 75%;
            }
        }

        .stroke-philosophy-icon {
            max-width: 50px;
        }

        .corporate-stroke {
            font-size: 5.062rem;
        }

        .margin-label-philosophy {
            margin-top: -4.2rem;
        }

        .hiring-btn {
            margin-top: 60px;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {

        .brand-margin {
            margin-top: -7rem;
            z-index: 1;
        }

        .hero-content {
            padding-top: 155px;
            min-height: auto;

            .team-video {
                top: 55%;
                width: 150px;
            }

            .team-info {
                width: 80%;
                margin-bottom: 30px;
            }
        }

        .glc-core-values {
            margin-top: 100px;

            .value-info {
                width: 80%;
            }

            .value-element {
                max-width: 292px;

                &.element-padding-top {
                    padding-top: 60px;
                }
            }

            .accountability {
                padding-top: 60px;
            }

            .glc-corporate {
                margin-top: 0;

                .text-behind-brand {
                    margin-bottom: 0;
                }

                .about-text-left,
                .about-text-right {
                    margin-top: 60px;
                }

                .corporate-philosophy-text {
                    width: 80%;
                }
            }

            h3.text-leadership-stroke {
                font-size: 1.875rem;
                line-height: 215px;
            }

            .value-stroke {
                font-size: 3.125rem;
                line-height: 155px;
            }
        }

        .glc-team {
            padding-top: 100px;
            padding-bottom: 100px;

            .team-info {
                width: 85%;
            }
        }

        .glc-perks {
            margin-top: 20px;

            .perks-flex {
                div.d-flex {
                    margin-bottom: 12px;
                }

                div.perks-element {
                    margin-bottom: 0;
                    padding: 0;
                    border: 0;

                    &.perk-container-gradient {
                        background: none;
                        box-shadow: none;
                    }

                    p {
                        line-height: 18px;
                    }
                }
            }

            .perk-info {
                width: 90%;
            }
            .value-heading {
                margin-top: -6rem;
            }
        }

        .glc-hiring-process {
            .hiring-text {
                width: 85%;
            }
        }

        .stroke-philosophy-icon {
            max-width: 50px;
        }

        .corporate-stroke {
            font-size: 5.062rem;
        }

        .margin-label-philosophy {
            margin-top: -4.2rem;
        }

        .glc-culture {
            padding: 33px 24px;
        }

        .glcHiringStepsMobile {
            max-width: 180px;
        }

        .hiring-btn {
            margin-top: 50px;

        }
    }
}