@import "../../sassStyles/colors";
@import "../../sassStyles/variables";

.apx-wrapper {
    .hero-content {
        padding-top: 9.375rem;

        h1 {
            z-index: 1;
            position: relative;
        }

        .apd-hero-image {
            width: 37%;
            position: relative;
            z-index: 1;
        }

        .director-margin {
            margin-left: 0.5rem;
        }
    }

    // APD Info Section
    .apd-left-gradient {
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        position: absolute;
        right: 0;
        top: 60%;
        height: 37.5rem;
        width: 37.5rem;
        filter: blur(12.5rem);
        z-index: 1;
    }

    .apd-info {
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        width: 72%;
        text-align: center;
        // color: $apdInfoColor;
        position: relative;
        z-index: 3;
        margin: auto;
        margin-top: 32px;

        span {
            color: $primaryGreenColor;
        }
    }

    .product-info-header {
        padding-top: 8.125rem;
        padding-bottom: 10.313rem;

        h2 {
            padding-top: 4.563rem;
            line-height: 124%;

            div {
                -webkit-text-stroke-width: 0.063rem;
                -webkit-text-stroke-color: $primaryGreenColor;
                font-size: 10.063rem;
                line-height: 124%;
                color: transparent;
            }
        }
    }

    .icon-container {
        gap: 2rem;
        padding: 5.313rem 0 11.625rem 0;

        .icon-font {
            font-size: 1.75rem;
        }
    }

    // SDN Control Section
    .sdn-control-slider {
        margin-top: 180px;
    }

    .sdn-control-heading {
        padding: 2rem 0 3.3rem 0;
    }

    .sdn-control-padding {
        padding-bottom: 7.5rem;
    }

    // Agility UseCases Section
    .agility-use-cases {
        padding: 8.688rem 0 3.8rem 0;
    }

    .apd-agility-middle-gradient {
        background: $bgGradient;
        transform: translate3d(0, 0, 0);
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 30%;
        height: 37.5rem;
        width: 37.5rem;
        filter: blur(12.5rem);
        z-index: 1;
    }

    .agility-diagram-padding {
        padding: 5rem 0 5rem 0;
        z-index: 3;
    }

    //APD Slider Section
    .slider {
        width: 100%;
        margin: auto;
        position: relative;
        display: grid;
        place-items: center;
        overflow: hidden;

        .slide-track {
            display: flex;
            width: calc((37.5rem + 3.75rem) * 3 * 1);
        }

        .slide {
            width: 37.5rem;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 3.75rem 3.75rem 0;

            img {
                max-width: 37.5rem !important;
            }
        }
    }

    .slider1 {
        .slide-track {
            animation: scroll1 15s linear infinite;
        }

        @keyframes scroll1 {
            0% {
                transform: translateX(0);
            }

            100% {
                // -1 * (slide width + margin offset) * no. of slides
                transform: translateX(calc(-1 * (37.5rem + 3.75rem) * 3));
            }
        }
    }

    .slider2 {
        .slide-track {
            animation: scroll2 15s linear infinite;
        }

        @keyframes scroll2 {
            0% {
                // -1 * (slide width + margin offset) * no. of slides
                transform: translateX(calc(-1 * (37.5rem + 3.75rem) * 3));
            }

            100% {
                transform: translateX(0);
            }
        }
    }

    .features-container {
        margin-top: 0;
        margin-bottom: 0;
    }
    .action-btn img{
        max-height: 18px;
    }

    // .view-apd-btn {
    //     padding-left: 50px;
    //     padding-right: 50px;
    // }

    @media (min-width: $minLargeDevice) and (max-width: $maxLargeDevice) {
        .apd-info {
            width: 68%;
        }

        .hero-content {
            min-height: 100vh;
            height: auto;

            .apd-hero-image {
                width: 43%;
            }
        }


        .sdn-control-slider {
            margin-top: 130px;
        }

        .agility-diagram-padding {
            width: 90% !important;
        }

        .features-container {

            padding-bottom: 4rem;

            .col-xl-3 {
                width: 33% !important;
            }
        }



        .product-info-header {
            padding-top: 8rem;
            padding-bottom: 8rem;

            img {
                width: 30%;
            }

            h2 div {
                font-size: 154px;
            }
        }

        .view-apd-btn img {
            width: auto;
        }

    }

    @media (min-width: $minMediumDevice) and (max-width: $maxMediumDevice) {
        .features-container .col-xl-3 {
            width: 33% !important;
        }

        .hero-content {
            min-height: 100vh;
            height: auto;

            .apd-hero-image {
                width: 60%;
            }
        }


        .apd-info {
            width: 65%;
        }

        .sdn-control-slider {
            margin-top: 110px;
        }

        .agility-diagram-padding {
            width: 80% !important;
        }

        .product-info-header {
            padding-top: 8rem;
            padding-bottom: 8rem;

            img {
                width: 25%;
            }

            h2 div {
                font-size: 115px;
            }
        }

        .view-apd-btn img {
            width: auto;
        }

    }

    @media (min-width: $minSmallDevice) and (max-width: $maxSmallDevice) {
        .hero-content {
            min-height: 100vh;
            height: auto;

            .apd-hero-image {
                width: 55%;
                z-index: 3;
            }

            h1 {
                z-index: 3;
            }
        }

        .apd-info {
            width: 60%;
        }

        .sdn-control-slider {
            margin-top: 100px;

        }

        .apd-logo-width {
            width: 20%;
        }

        .slider .slide img {
            max-width: 27rem !important;
        }

        .agility-diagram-padding {
            width: 80% !important;
            padding: 3rem 0 3rem 0;
        }

        .agility-use-cases {
            padding: 100px 0 70px 0;

        }

        .product-info-header {
            padding-top: 6rem;
            padding-bottom: 6rem;

            img {
                width: 25%;
            }

            h2 {
                padding-bottom: 2rem;

                div {
                    font-size: 85px;
                }

            }

        }

        .view-apd-btn img {
            width: auto;
        }

    }

    @media (min-width: $minTabDevice) and (max-width: $midSmallDevice) {
        .hero-content {
            min-height: 0;
            height: auto;

            .apd-hero-image {
                width: 72%;
                z-index: 3;
            }

            h1 {
                z-index: 3;
            }
        }

        .sdn-control-slider {
            margin-top: 90px;
        }

        .slider .slide img {
            max-width: 280px !important;
        }

        .sdn-control-padding {
            padding-bottom: 90px;
        }

        .agility-use-cases {
            padding: 70px 0 40px 0;
        }

        .agility-diagram-padding {
            padding: 3rem 0 3rem 0;
        }

        .product-info-header {
            padding-top: 4rem;
            padding-bottom: 4rem;

            img {
                width: 25%;
            }

            h2 {
                padding-bottom: 1rem;
                padding-top: 2rem;

                div {
                    font-size: 90px;
                }

            }

        }

        .view-apd-btn img {
            width: auto;
        }
    }

    @media (min-width: $minMobileDevice) and (max-width: $maxMobileDevice) {
        .hero-content {
            min-height: 0;
            height: auto;

            .apd-hero-image {
                width: 90%;
                z-index: 2;
            }

        }


        .apd-left-gradient {
            right: 0;
            left: 0;
            margin: auto;
            top: 8rem;
            height: 270px;
            width: 290px;
            filter: blur(70px);
        }

        .apd-info {
            width: 96%;
            z-index: 2;
        }

        .sdn-control-slider {
            margin-top: 60px;
        }

        .apd-logo-width {
            width: 40%;
        }

        .sdn-control-heading {
            padding: 14px 0 14px 0;
        }


        .icon-container {
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 50px;

            p {
                text-align: center;
                font-size: 18px;
                line-height: 120%;
            }
        }

        .sdn-control-padding {
            padding-bottom: 4rem;
        }

        .slider .slide img {
            max-width: 310px !important;
        }

        .agility-use-cases {
            padding: 50px 0 50px 0;
        }

        .agility-diagram-padding {
            width: 85%;
            padding: 50px 0 50px 0;
        }

        .features-container {
            margin-top: 0;
        }

        .product-info-header {
            padding: 50px 0 50px 0;

            img {
                width: 60%;
            }

            h2 {
                padding-top: 32px;

                div {
                    font-size: 45.7632px;
                    line-height: 124%;

                }
            }

        }

        .view-apd-btn img {
            width: auto;
        }


    }

}